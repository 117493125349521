import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "currency"
  ]

  activate() {
    this.currencyTarget.disabled = !this.currencyTarget.disabled
    this.currencyTarget.classList.toggle("opacity-0")
  }
}
