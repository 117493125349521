import { Controller } from "stimulus"
import Highcharts from "highcharts"
const moment = require('moment')
import Rails from "rails-ujs"
import { useLoading } from '../mixins/useLoading.js'

export default class extends Controller {
  connect() {
    useLoading(this)
  }

  display(e) {
    this.showLoading()
    Rails.ajax({
      type: "GET",
      url: `/portfolios/${e.detail.portfolio_id}/performance.json?start=${e.detail.startAt}&end=${e.detail.endAt}`,
      success: (data) => {
        this.chart = Highcharts.chart(this.element, this.perfGraphOptions(data))
        this.hideLoading()
      }
    })
  }

  perfGraphOptions(data) {
    let options = {
      chart: {
        type: 'line',
        spacingBottom: 0,
        spacingLeft: 0,
        height: 300
      },
      margin: 0,
      title: {
        text: null
      },
      legend: { enabled: false },
      plotOptions: {
        line: {
          marker: {
            enabled: false
          }
        }
      },
      xAxis: {
        type: 'datetime',
        lineColor: "#D6E1EB",
        alignTicks: false,
        tickColor: "#D6E1EB",
        labels: {
          style: {
            color: "#8694A2",
            fontSize: 10
          }
        }
      },
      yAxis: {
        title: {
          text: 'Valeur',
          style: {
            color: "#8694A2"
          }
        },
        labels: {
          style: {
            color: "#8694A2",
            fontSize: 10
          }
        },
        lineWidth: 1,
        lineColor: "#A1AEBB",
        startOnTick: true,
        endOnTick: true,
        minTickInterval: 0.5,
        tickColor: "#8694A2",
        plotLines: [{
          color: '#A1AEBB',
          width: 1,
          value: 100,
          zIndex: 2
        }]
      },
      loading: {
        labelStyle: {
          color: 'white'
        },
        style: {
          backgroundColor: 'gray'
        }
      },
      lang: {
        loading: "Chargement..."
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        shadow: {
          width: 10,
          opacity: 0.02
        },
        style: {
          fontFamily: 'work sans',
        },
        borderWidth: 0,
        outside: true,
        crosshairs: [true, true],
        useHTML: true,
        formatter: function () {
          moment.locale('fr')
          return this.points.reduce(function (s, point) {
            return `
            ${s}
            <div class="mt-2 ${point.series.name == "Profil" ? "text-primary" : "text-muted"}">
              <div class="d-flex align-items-center text-muted">
                <div class="mr-1" style="width: 10px; height: 10px; background: ${point.series.color}"></div>
                ${point.series.name}
              </div>
              <div class="font-lg line-height-sm ${point.series.name == "Profil" ? "font-weight-medium" : ""}">${point.y.toFixed(2)}</div>
            </div>
          `
          }, `<div class="text-center">${moment(this.x).format("D MMMM YYYY")}</div>`);
        },
        shared: true
      },
      series: []
    }
    if (this.data.get("simple")) {
      options['chart']['height'] = 166
      options['margin'] = [0, 0, 0, 0]
    }
    if (data.portfolio.length > 0) {
      options.series.push({
        type: 'line',
        name: 'Profil',
        data: data.portfolio,
        color: "#FFC83C",
        zIndex: 2
      })
    }
    if (data.category_reference) {
      options.series.push({
        type: 'line',
        name: "Indice",
        data: data.category,
        color: "#2619BE"
      })
    }

    return options
  }
}