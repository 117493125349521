import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "value",
    "table",
    "categoriesSelector",
    "totalPerf"
  ]

  connect() {
    document.querySelectorAll('#column-checkboxes [type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = 'checked'
    })

    let categories = [...this.tableTarget.querySelectorAll('tr[data-category-label]')].map((el) => {
      return {
        id: el.dataset.categoryId,
        label: el.dataset.categoryLabel
      }
    }).filter(item => item.id).filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index)

    categories.forEach((category) => {
      let link = `
        <a class="dropdown-item"
           href="#"
           data-action="reporting-inventory#toggleCategory"
           data-category-id="${category.id}">
          ${category.label}
        </a>
      `
      this.categoriesSelectorTarget.insertAdjacentHTML('beforeend', link)
    })

    this.tableTarget.querySelectorAll('tr[data-category-label]')

    this.totalContrib("all")
  }

  totalContrib(categoryId) {
    let selector = (categoryId == "all") ? 'tr[data-contrib]' : `tr[data-category-id="${categoryId}"]`
    const totalContrib = [...this.tableTarget.querySelectorAll(selector)].reduce((total, el) => {
      return total + parseFloat(el.dataset.contrib)
    }, 0)

    this.totalPerfTarget.innerHTML = `${totalContrib.toFixed(2)}&nbsp;%`
  }

  toggleCategory(event) {
    const categoryId = event.currentTarget.dataset.categoryId

    if (categoryId == "all") {
      this.tableTarget.querySelectorAll(`tr[data-category-id]`).forEach((el) => {
        el.classList.remove('d-none')
      })
      this.totalContrib("all")
      return
    }
    this.tableTarget.querySelectorAll(`tr[data-category-id]`).forEach((el) => {
      el.classList.add('d-none')
    })
    this.tableTarget.querySelectorAll(`tr[data-category-id="${categoryId}"]`).forEach((el) => {
      el.classList.remove('d-none')
    })
    this.totalContrib(categoryId)
  }

  toggleColumn(event) {
    event.stopPropagation()
    let checked = event.currentTarget.querySelector('[type="checkbox"]').checked
    let column = event.currentTarget.querySelector('[type="checkbox"]').id
    let el = document.querySelector('[data-column="' + column + '"]')
    let index = [...el.parentElement.children].indexOf(el)

    this.tableTarget.querySelectorAll('tr').forEach((tr) => {
      if (checked) {
        tr.children[index].classList.remove("d-none")
      } else {
        tr.children[index].classList.add("d-none")
      }
    })
  }
}
