import { Controller } from "stimulus"
import Rails from 'rails-ujs'
import { useDispatch } from 'stimulus-use'
import "summernote"
import 'summernote/dist/summernote-bs4.css';
const moment = require('moment')

export default class extends Controller {
  static targets = [
    "intel",
    "profileList",
    "subProfileList",
    "portfolioList"
  ]

  connect() {
    useDispatch(this)
    this.initFormOptions()
  }

  initFormOptions() {
    let intel = this.intelTarget
    this.setOption(this.profileListTarget, intel.dataset.profileId)
    this.setSubProfilesList(intel.dataset.profileId, intel)
  }

  setOption(target, id) {
    let options = Array.from(target.options)
    let option = this.findOption(options, parseInt(id))
    target.selectedIndex = option.index
  }

  findOption(options, id) {
    return options.find((element) => element.value == id)
  }

  setSubProfilesList(profileId, intel) {
    Rails.ajax({
      type: "GET",
      url: "/admin/profiles/" + profileId + "/sub_profiles",
      success: (data) => {
        var subProfileList = this.subProfileListTarget
        subProfileList.disabled = false
        var child = subProfileList.lastElementChild
        while (child) {
          subProfileList.removeChild(child)
          child = subProfileList.lastElementChild
        }

        var option = document.createElement('option')
        option.appendChild( document.createTextNode("-- Choisir un sous profil --") )
        subProfileList.appendChild(option)

        data.forEach(function(subProfile){
          var option = document.createElement('option')
          option.appendChild( document.createTextNode(subProfile.name) )
          option.value = subProfile.id
          subProfileList.appendChild(option)
        })
        this.setOption(this.subProfileListTarget, intel.dataset.subProfileId)
        this.setPortfoliosList(intel.dataset.subProfileId, intel)
      }
    })
  }

  setPortfoliosList(subProfileId, intel) {
    Rails.ajax({
      type: "GET",
      url: "/admin/sub_profiles/" + subProfileId + "/portfolios",
      success: (data) => {
        var portfolioList = this.portfolioListTarget
        portfolioList.disabled = false
        var child = portfolioList.lastElementChild
        while (child) {
          portfolioList.removeChild(child)
          child = portfolioList.lastElementChild
        }

        var option = document.createElement('option')
        option.appendChild( document.createTextNode("-- Choisir un portefeuille --") )
        portfolioList.appendChild(option)

        data.forEach(function(portfolio){
          var option = document.createElement('option')
          option.appendChild( document.createTextNode(portfolio.name) )
          option.value = portfolio.id
          portfolioList.appendChild(option)
        })
        this.setOption(this.portfolioListTarget, intel.dataset.portfolioId)

        this.dispatch('setPortfoliosList', { 
          value: intel.dataset.profileId,
          date: intel.dataset.date,
          kind: 'draft'
        })
      }
    })
  }

}
