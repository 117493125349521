import { Controller } from "stimulus"
import Highcharts from "highcharts"

export default class extends Controller {

  connect() {
    var options = {
      chart: {
        type: 'column',
        height: 230
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        shadow: {
          width: 10,
          opacity: 0.02
        },
        style: {
          fontFamily: 'work sans',
        },
        borderWidth: 0,
        outside: true,
        useHTML: true,
        formatter: function () {
          return this.points.reverse().reduce(function (s, point) {
            return `
              ${s}
              <div class="mt-2 ${point.series.name == "Fonds" ? "text-primary" : "text-muted" }">
                <div class="d-flex align-items-center text-muted">
                  <div class="mr-1" style="width: 10px; height: 10px; background: ${point.series.color}"></div>
                  ${point.series.name}
                </div>
                <div class="font-lg line-height-sm font-weight-medium">${point.y.toFixed(2)}%</div>
              </div>
            `
          }, `<div class="text-center">${this.x}</div>`);
        },
        shared: true
      },
      xAxis: {
        type: "linear",
        categories: JSON.parse(this.data.get('labels')),
        labels: {
          style: {
            color: '#8694A2'
          }
        },
        lineWidth: 1,
        lineColor: "#D6E1EB",
        tickInterval: 1,
        tickWidth: 1,
        tickmarkPlacement: "on"
      },
      yAxis: {
        allowDecimals: true,
        title: {
          text: null
        },
        labels: {
          format: '{value}%',
          style: {
            color: '#8694A2'
          }
        },
        lineWidth: 1,
        lineColor: "#A1AEBB",
        plotLines: [{
          color: '#A1AEBB',
          width: 1,
          value: 0,
          zIndex: 2
        }]
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          grouping: true,
          shadow: false,
          borderWidth: 0
        },
        series: {
          minPointLength: 3
        }
      },
      series: [
        {
          name: 'Catégorie',
          data: JSON.parse(this.data.get('category')),
          pointPadding: 0,
          pointPlacement: 0.1,
          color: "#2E513E",
          pointWidth: 26
        },
        {
          name: 'Fonds',
          data: JSON.parse(this.data.get('share')),
          pointPadding: 3,
          pointPlacement: -0.1,
          color: "#4ECF8C",
          pointWidth: 26
        }
      ]
    }

    setTimeout(function() {
      this.chart = Highcharts.chart(this.context.element, options)
    }.bind(this), 100)
  }
}
