import { Controller } from "stimulus"
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = [
    "portfolioMissing",
    "portfolioPresent"
  ]

  connect() {
    useDispatch(this)

    this.portfolio_id = this.data.get("portfolioId")
    this.period_id = this.data.get("periodId")
    this.startAt = this.data.get("startAt")
    this.endAt = this.data.get("endAt")
    this.category_id = this.data.get("categoryId")
    if (this.portfolio_id && this.period_id) {
      this.portfolioMissingTarget.classList.add('d-none')
      this.portfolioPresentTarget.classList.remove('d-none')
      this.refresh()
    } else {
      this.portfolioMissingTarget.classList.remove('d-none')
      return false
    }
  }

  refresh() {
    setTimeout(function() {
      this.dispatch('refresh', {
        portfolio_id: this.portfolio_id,
        startAt: this.startAt,
        endAt: this.endAt,
        category_id: this.category_id
      })
    }.bind(this), 100)
  }
  changePortfolio(event) {
    this.data.set("portfolioId", event.currentTarget.options[event.currentTarget.selectedIndex].value)
    this.connect()
  }
}