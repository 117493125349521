import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "prev",
    "currentDate",
    "next",
    "arbitrage"
   ]

  connect() {
    this.arbitrageTargets.forEach(function(arbitrage) {
      arbitrage.classList.add("d-none")
    })
    this.showArbitrage(this.arbitrageTargets.pop())
  }

  showArbitrage(arbitrage) {
    this.current = arbitrage
    arbitrage.classList.remove("d-none")
    this.currentDateTarget.innerHTML = arbitrage.dataset.date

    this.prevTarget.classList.remove("opacity-0")
    this.nextTarget.classList.remove("opacity-0")

    if (arbitrage == this.arbitrageTargets.pop()) {
      this.nextTarget.classList.add("opacity-0")
    }
    if (arbitrage == this.arbitrageTargets[0]) {
      this.prevTarget.classList.add("opacity-0")
    }
  }

  next(e) {
    e.preventDefault()
    this.slide(+1)
  }

  prev(e) {
    e.preventDefault()
    this.slide(-1)
  }

  slide(move) {
    let index = this.arbitrageTargets.indexOf(this.current)
    if (this.arbitrageTargets[index + move]) {
      this.current.classList.add("d-none")
      this.showArbitrage(this.arbitrageTargets[index + move])
    }
  }
}
