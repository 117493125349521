import { Controller } from "stimulus"
import Highcharts from "highcharts"

export default class extends Controller {

  connect() {
    var options = {
      chart: {
        type: 'bar',
        height: 230
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        shadow: {
          width: 10,
          opacity: 0.02
        },
        style: {
          fontFamily: 'work sans',
        },
        borderWidth: 0,
        outside: true,
        useHTML: true,
        formatter: function () {
          return `
            <div class="">
              <div class="d-flex align-items-center text-muted">
                ${this.x}
              </div>
              <div class="font-lg line-height-sm font-weight-medium">${this.y.toFixed(2)}%</div>
            </div>
          `
        }
      },
      xAxis: {
        type: "linear",
        categories: JSON.parse(this.data.get('labels')),
        labels: {
          style: {
            color: '#8694A2'
          }
        },
        lineWidth: 1,
        lineColor: "#D6E1EB",
        tickInterval: 1,
        tickWidth: 1,
        tickmarkPlacement: "on"
      },
      yAxis: {
        opposite: "true",
        allowDecimals: true,
        title: {
          text: null
        },
        labels: {
          format: '{value}%',
          style: {
            color: '#8694A2'
          }
        },
        lineWidth: 0,
        plotLines: [{
            color: '#A1AEBB',
            width: 1,
            value: 0,
            zIndex: 2
        }]
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        bar: {
          grouping: true,
          shadow: false,
          borderWidth: 0
        },
        series: {
          minPointLength: 3
        }
      },
      series: [
        {
          name: 'Fonds',
          data: JSON.parse(this.data.get('share')),
          pointPadding: 3,
          pointPlacement: 0,
          color: "#4ECF8C",
          pointWidth: 12
        }
      ]
    }

    setTimeout(function() {
      this.chart = Highcharts.chart(this.context.element, options)
    }.bind(this), 100)
  }
}
