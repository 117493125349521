import { Controller } from "stimulus"
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    "subProfileList",
    "portfolioList",
  ]

  connect() {
  }

  setSubProfilesList(event) {
    var profileId = event.target.value
    Rails.ajax({
      type: "GET",
      url: "/admin/profiles/" + profileId + "/sub_profiles",
      success: (data) => {
        var subProfileList = this.subProfileListTarget
        subProfileList.disabled = false
        var child = subProfileList.lastElementChild
        while (child) {
          subProfileList.removeChild(child)
          child = subProfileList.lastElementChild
        }

        var option = document.createElement('option')
        option.appendChild( document.createTextNode("-- Choisir un sous profil --") )
        subProfileList.appendChild(option)

        data.forEach(function(subProfile){
          var option = document.createElement('option')
          option.appendChild( document.createTextNode(subProfile.name) )
          option.value = subProfile.id
          subProfileList.appendChild(option)
        })
      }
    })
  }

  setPortfoliosList(event) {
    var subProfileId = event.target.value
    Rails.ajax({
      type: "GET",
      url: "/admin/sub_profiles/" + subProfileId + "/portfolios",
      success: (data) => {
        var portfolioList = this.portfolioListTarget
        portfolioList.disabled = false
        var child = portfolioList.lastElementChild
        while (child) {
          portfolioList.removeChild(child)
          child = portfolioList.lastElementChild
        }

        var option = document.createElement('option')
        option.appendChild( document.createTextNode("-- Choisir un portefeuille --") )
        portfolioList.appendChild(option)

        data.forEach(function(portfolio){
          var option = document.createElement('option')
          option.appendChild( document.createTextNode(portfolio.name) )
          option.value = portfolio.id
          portfolioList.appendChild(option)
        })
      }
    })
  }
}
