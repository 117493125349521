import { Controller } from "stimulus"
import Rails from 'rails-ujs'
import { useDispatch } from 'stimulus-use'
import "summernote"
import 'summernote/dist/summernote-bs4.css';
import flatpickr from 'flatpickr';
import { French } from "flatpickr/dist/l10n/fr.js"
const moment = require('moment')

export default class extends Controller {
  static targets = [
    "subProfileList",
    "portfolioList",
    "datePicker",
    "lastNavDate",
    "endDate",
    "inventoryTitle",
    "inventoryDate",
    "loadingDraft",
    "inventory",
    "positionQuotePart",
    "totalQuotePart",
    "spForm",
    "effectiveDateSpForm",
    "submitButton"
  ]

  connect() {

  }

  setSubProfilesList(event) {
    var profileId = event.target.value
    Rails.ajax({
      type: "GET",
      url: "/admin/profiles/" + profileId + "/sub_profiles",
      success: (data) => {
        var subProfileList = this.subProfileListTarget
        subProfileList.disabled = false
        var child = subProfileList.lastElementChild
        while (child) {
          subProfileList.removeChild(child)
          child = subProfileList.lastElementChild
        }

        var option = document.createElement('option')
        option.appendChild( document.createTextNode("-- Choisir un sous profil --") )
        subProfileList.appendChild(option)

        data.forEach(function(subProfile){
          var option = document.createElement('option')
          option.appendChild( document.createTextNode(subProfile.name) )
          option.value = subProfile.id
          subProfileList.appendChild(option)
        })
      }
    })
  }

  setPortfoliosList(event) {
    event.preventDefault()
    this.hideDatePicker()

    var subProfileId = event.target.value
    Rails.ajax({
      type: "GET",
      url: "/admin/sub_profiles/" + subProfileId + "/portfolios",
      success: (data) => {
        var portfolioList = this.portfolioListTarget
        portfolioList.disabled = false
        var child = portfolioList.lastElementChild
        while (child) {
          portfolioList.removeChild(child)
          child = portfolioList.lastElementChild
        }

        var option = document.createElement('option')
        option.appendChild( document.createTextNode("-- Choisir un portefeuille --") )
        portfolioList.appendChild(option)

        data.forEach(function(portfolio){
          var option = document.createElement('option')
          option.appendChild( document.createTextNode(portfolio.name) )
          option.value = portfolio.id
          portfolioList.appendChild(option)
        })
      }
    })
  }

  displayForm(event) {
    event.preventDefault()
    this.getLastNavDate()
    this.displayDatePicker()
  }
  
  getLastNavDate(details) {
    let _this = this
    let portfolioId = this.portfolioListTarget.value
    let last_url = `/admin/portfolios/${portfolioId}/last_nav`
    Rails.ajax({
      type: "GET",
      url: last_url,
      success: (data) => {
        let last_nav_date = new Date(data.last_nav_date)
        _this.displayLastNavDate(last_nav_date)

        if (details) {
          var set_date = new Date(details.date)
        } else {
          var set_date = last_nav_date
        }
        _this.setLastNavDate(set_date)
        _this.setInventory(set_date, details)
      }
    })
  }

  setInventory(date, details) {
    let _this = this
    let portfolioId = this.portfolioListTarget.value
    let effectiveDate = this.dateStringFormat(date)
    let inventory_url = `/admin/scheduled_payment_arbitrages/${portfolioId}/editable_inventory?effective_date=${effectiveDate}`

    this.showLoading(this.inventoryTarget)
    this.inventoryTarget.classList.add('p-5')

    Rails.ajax({
      type: "GET",
      url: inventory_url,
      success: (data) => {
        _this.inventoryTarget.innerHTML = data.html
        _this.setInventoryDate(date)
        _this.showElement(_this.inventoryTitleTarget)
        _this.hideLoading(_this.inventoryTarget)

        _this.inventoryTarget.classList.remove('p-5')
      }
    })
  }

  setLastNavDate(date) {
    flatpickr(this.endDateTarget, {
      altInput: true,
      allowInput: true,
      altFormat: 'd/m/Y',
      dateFormat: "Y-m-d",
      locale: French,
      defaultDate: new Date(date)
    })
  }

  displayLastNavDate(date) {
    date = this.dateStringFormat(date)
    this.lastNavDateTarget.innerHTML = this.lastNavDateElement(date)
    this.lastNavDateTarget.dataset.date = date
  }

  setInventoryDate(date) {
    date = this.dateStringFormat(date)
    this.inventoryDateTarget.textContent = date
  }

  getDateFromPicker() {
    let calendar = this.endDateTarget
    let date = new Date(calendar.value)
    return date
  }

  lastAvailableDate(date) {
    let lastNavDate = new Date(this.lastNavDateTarget.dataset.date)
    if (date > lastNavDate) { date = lastNavDate }
    return date
  }

  totalQuotePart() {
    return this.positionQuotePartTargets.reduce(function(sum, input) {
      let v = 0.0
      if (input.value !== '') { v = parseFloat(input.value) }
      return sum + v
    }, 0.0)
  }

  processTotalQuotePart() {
    this.totalQuotePartTarget.innerHTML = this.totalHtml(this.totalQuotePart().toFixed(2))
    if (this.totalQuotePart() == 100.0) {
      this.showElement(this.submitButtonTarget)
    } else {
      this.hideElement(this.submitButtonTarget)
    }
  }

  // sp-form --------------------------------------------------------------------------
  setDateForm(event) {
    event.preventDefault()
    let date = this.getDateFromPicker()
    this.setEffectiveDateSp(date)
  }

  setEffectiveDateSp(date) {
    date = this.dateStringFormat(date)
    this.effectiveDateSpFormTarget.value = date
  }

  // html / css elements / format -----------------------------------------------------
  displayDatePicker() {
    this.datePickerTarget.classList.remove('d-none')
  }

  hideDatePicker() {
    this.datePickerTarget.classList.add('d-none')
  }

  lastNavDateElement(date) {
    return `<div class="bg-secondary-light px-3 mx-3 py-2">
      Dernière date de valorisation du portefeuille : ${date}
     </div>`
  }

  dateStringFormat(date) {
    return date.toISOString().split('T')[0]
  }

  showLoadingElements(title, table) {
    this.showElement(title)
    this.showLoading(table)
    table.classList.add('p-5')
  }

  hideLoadingElements(table) {
    table.classList.remove('p-5')
    this.hideLoading(table)
  }

  showLoading(el) {
    el.insertAdjacentHTML('beforeend', this.loadingElement())
  }

  hideLoading(el) {
    if (el.querySelector('.loading')) {
      el.querySelector('.loading').remove()
    }
  }

  loadingElement() {
    return `
      <div class="loading bg-white d-flex justify-content-center align-items-center">
        <div class="spinner-grow text-secondary" role="status" style="width: 6rem; height: 6rem;">
          <span class="sr-only">Chargement...</span>
        </div>
      </div>
    `
  }

  hideElement(el) {
    el.classList.add('d-none')
  }

  showElement(el) {
    el.classList.remove('d-none')
  }

  totalHtml(total) {
    return `
      <strong class="${total == 100 ? 'text-success' : 'text-danger'}">
        ${total != 100 ? '❌' : '✅'}
        TOTAL: ${isNaN(total) ? "ERREUR" : total + "%"}
      </strong>
    `
  }

}
