import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
  }

  add_association(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association_with_confirm(event) {
    event.preventDefault()
    if (confirm(event.currentTarget.dataset.confirmation)) {
      this.remove_association(event)
    }
  }

  remove_association(event) {
    event.preventDefault()
    let wrapper = event.target.closest("." + this.wrapperClass)
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.classList.add('d-none')
    }
  }
}
