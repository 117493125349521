// <%=
//   link_to "my link", "#",
//       data: {
//         controller: "open-modal",
//         action: "click->open-modal#open",
//         url: my_path
//       }
// %>
// `my_path` should respond with json with a key named "html",
// it will be used to fill in the modal content

import { Controller } from "stimulus"
import Rails from "rails-ujs"

export default class extends Controller {

  open(event) {
    event.preventDefault()
    if (document.getElementById('modalOpen'))
      document.getElementById('modalOpen').remove()

    const url = event.currentTarget.dataset.url

    Rails.ajax({
      type: "GET",
      url: url,
      success: (data) => {
        let modalHTML = this.modal(data.html)
        document.body.insertAdjacentHTML('beforeend', modalHTML)
        $('#modalOpen').modal('show')
      }
    })
  }

  modal(content) {
    return `
      <div class="modal fade" tabindex="-1" id="modalOpen" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            ${content}
          </div>
        </div>
      </div>
    `
  }

}



