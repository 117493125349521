import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'formTemplate',
    'funds',
    'fundsList',
    'searchForm',
    'searchFormWrapper',
    'searchField',
    'client',
    'main',
    'noFunds'
  ]

  // events
  connect() {
    this.resetFirstAndLastClasses()
    this.focusSearchField()
    this.resetSearchForm()
    this.disableClientIfMain()
  }

  mainChanged(event) {
    if (event.target.checked) {
      this.clientTarget.value = ""
      this.clientTarget.disabled = true
    } else {
      this.clientTarget.disabled = false
    }
  }

  add(event) {
    event.preventDefault()

    var fundId = event.target.dataset.fundId
    var fundName = event.target.dataset.fundName
    var nextPosition = this.nextPosition()
    if (this.fundInList(fundId)) {
      return false
    }
    var newFund = document.importNode(this.formTemplateTarget.content, true)
    newFund.querySelector('.fund-position').value = nextPosition + 1
    newFund.querySelector('.fund-id').value = fundId
    newFund.querySelectorAll('input').forEach(function(element, i) {
      var newName = element.name.replace("FUND_INDEX", nextPosition)
      element.setAttribute('name', newName)
    }, this)
    newFund.querySelector('[data-position]').innerHTML = nextPosition + 1
    newFund.querySelector('[data-name]').innerHTML = fundName
    newFund.querySelector('[data-fund]').dataset.fund = fundId
    this.fundsListTarget.appendChild(newFund)
    this.resetFirstAndLastClasses()
    this.hideNoFund()
  }

  remove(event) {
    event.preventDefault()
    var fund = event.target.closest('[data-fund]')
    fund.querySelector('.fund-remove').checked = true
    fund.classList.add('d-none')
    fund.classList.remove('category-fund')
    var nextFund = this.getNextSibling(fund, '[data-fund]:not(.d-none)')
    while (nextFund) {
      this.moveHigher(nextFund)
      nextFund = this.getNextSibling(nextFund, '[data-fund]:not(.d-none)')
    }
    this.resetFirstAndLastClasses()
    if (this.fundsTarget.querySelector('.list-group-item:not(.d-none)')) {
      this.hideNoFund()
    } else {
      this.showNoFund()
    }
  }

  up(event) {
    event.preventDefault()
    var fundToMoveHigher = event.target.closest('[data-fund]')
    var fundToMoveLower = this.getPreviousSibling(event.target.closest('[data-fund]'), '[data-fund]:not(.d-none)')
    this.moveHigher(fundToMoveHigher)
    this.moveLower(fundToMoveLower)
    fundToMoveHigher.parentNode.insertBefore(fundToMoveHigher, fundToMoveLower)
    this.resetFirstAndLastClasses()
  }

  down(event) {
    event.preventDefault()
    var fundToMoveLower = event.target.closest('[data-fund]')
    var fundToMoveHigher = this.getNextSibling(event.target.closest('[data-fund]'), '[data-fund]:not(.d-none)')
    this.moveHigher(fundToMoveHigher)
    this.moveLower(fundToMoveLower)
    fundToMoveHigher.parentNode.insertBefore(fundToMoveHigher, fundToMoveLower)
    this.resetFirstAndLastClasses()
  }

  // private

  hideNoFund() {
    this.noFundsTarget.classList.add('d-none')
  }
  showNoFund() {
    this.noFundsTarget.classList.remove('d-none')
  }

  disableClientIfMain() {
    if (this.mainTarget.checked) {
      this.clientTarget.value = ""
      this.clientTarget.disabled = true
    }
  }

  resetSearchForm() {
    $('#fundSearchModal').on('show.bs.modal', { this: this }, function (event) {
      var searchForm = document.getElementById("fund-search");
      searchForm.reset();
    });
  }
  focusSearchField() {
    $('#fundSearchModal').on('shown.bs.modal', { this: this }, function (event) {
      event.data.this.searchFieldTarget.focus()
    })
  }

  moveHigher(fund) {
    var actualPosition = parseInt(fund.querySelector('.fund-position').value)
    fund.querySelector('[data-position]').innerHTML = actualPosition - 1
    fund.querySelector('.fund-position').value = actualPosition - 1
  }

  moveLower(fund) {
    var actualPosition = parseInt(fund.querySelector('.fund-position').value)
    fund.querySelector('[data-position]').innerHTML = actualPosition + 1
    fund.querySelector('.fund-position').value = actualPosition + 1
  }

  fundInList(fundId) {
    return this.fundsIds().includes(parseInt(fundId))
  }

  fundsIds() {
    return Array.prototype.map.call(
      this.fundsTarget.querySelectorAll('[data-fund]'),
      function(item) {
        return parseInt(item.dataset.fund)
      }
    )
  }

  resetFirstAndLastClasses() {
    var first = document.querySelector('.category-fund-first')
    if (first)
      first.classList.remove('category-fund-first')
    var last = document.querySelector('.category-fund-last')
    if (last)
      last.classList.remove('category-fund-last')
    var categoryFunds = document.querySelectorAll('.category-fund')
    if (categoryFunds.length > 0) {
      categoryFunds[0].classList.add('category-fund-first')
      categoryFunds[categoryFunds.length - 1].classList.add('category-fund-last')
    }
  }

  nextPosition() {
    return this.fundsTarget.querySelectorAll('[data-fund]').length
  }

  getPreviousSibling(elem, selector) {
  	var sibling = elem.previousElementSibling
  	if (!selector) return sibling
  	while (sibling) {
  		if (sibling.matches(selector)) return sibling
  		sibling = sibling.previousElementSibling
  	}
  }
  getNextSibling(elem, selector) {
  	var sibling = elem.nextElementSibling
  	if (!selector) return sibling
  	while (sibling) {
  		if (sibling.matches(selector)) return sibling
  		sibling = sibling.nextElementSibling
  	}
  }
}
