{/*
  <div data-controller="count-characters" data-max-chars="2450">
    <%= f.input :structure, as: :text, input_html: {class: "summernote" } %>
    <div data-target="count-characters.counter"></div>
  </div>
*/}


import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "counter"
  ]

  connect() {
    this.summernote = this.element.querySelector(".summernote")
    this.maxLength = parseInt(this.element.dataset.maxChars) || 2000

    this.countChars(this.summernote.value)

    let that = this
    $(this.summernote).on('summernote.change', function (we, contents, $editable) {
      that.countChars(contents)
    })
  }

  countChars(contents) {
    let content = this.removeTags(contents)
    let length = content.length

    if (length >= this.maxLength) {
      this.displayCharCounter(length, "danger")
    } else if (length >= this.maxLength - (this.maxLength / 10)) {
      this.displayCharCounter(length, "warning")
    } else {
      this.displayCharCounter(length, "muted")
    }
  }

  hideCharCounter() {
    this.counterTarget.innerHTML = ""
  }


  displayCharCounter(length, color = "danger") {
    this.counterTarget.innerHTML = `
      <div class="text-right text-${color} mb-5">
        Nombre de caractères : ${length} / ${this.maxLength}
      </div>
    `
  }

  removeTags(str) {
    if ((str === null) || (str === ''))
      return false
    else
      str = str.toString()
    return str.replace(/(<([^>]+)>)/ig, '')
  }
}

