import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "input"
  ]

  generate(e) {
    e.preventDefault()
    this.inputTarget.value = this.newToken()
  }

  newToken() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }
}
