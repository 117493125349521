import { createConsumer } from "@rails/actioncable"

const App = {};
App.cable = createConsumer();

window.breakdownsImportSubscription = function() {
  App.cable.subscriptions.create({
    channel: "ImportBreakdownsChannel",
  },
  {
    received: function(data) {
      let html = ""
      if (data.status == "done") {
        html = `<h4>Import terminé !</h4>`
        document.getElementById('importLoading').innerHTML = html
        return true
      } else if (data.status == "ok") {
        // html = `<p>✅ ${data.isin}</p>`
      } else if (data.status == "not_found") {
        html = `<p>❌ NON TROUVÉ : <strong>${data.isin}</strong> - ${data.errors}</p>`
      } else if (data.status == "not_created") {
        html = `<p>❌ NON CRÉÉ : <strong>${data.isin}</strong> - ${data.errors} </p>`
      } else if (data.status == "file_error") {
        html = `
          <div class=" alert alert-danger text-center m-5">
            ❌❌❌ Le fichier est mal formaté ❌❌❌
          </div>
        `
        document.querySelector('#importBreakdowns .modal-content').innerHTML = html
        return true
      } else {
        html = `<p>❌ ERREUR : ${data.isin}</p>`
      }
      document.getElementById('importLogs').insertAdjacentHTML('beforeend', html)
    }
  })
}
