import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "value"
  ]

  connect() {
    this.min = Math.min(...this.values())
    this.max = Math.max(...this.values())
    this.valueTargets.forEach((valueTarget) => {
      if (isNaN(parseFloat(valueTarget.dataset.value))) { return }

      const percentage = this.valueToPercentage(parseFloat(valueTarget.dataset.value))
      valueTarget.style.color = this.valueToColor(percentage)
    })
  }

  values() {
    return this.valueTargets.map(valueTarget => parseFloat(valueTarget.dataset.value))
                            .filter((value) => !Number.isNaN(value))
  }

  valueToPercentage(value) {
    const from = [this.min, this.max]
    const to = [0, 100]
    const scale = (to[1] - to[0]) / (from[1] - from[0])
    const capped = Math.min(from[1], Math.max(from[0], value)) - from[0]
    return (capped * scale) + to[0]
  }

  valueToColor(percentage) {
    let color = ""
    if (this.between(percentage, 0, 20)) { color = "#FF684B" }
    if (this.between(percentage, 20, 40)) { color = "#FF9943" }
    if (this.between(percentage, 40, 60)) { color = "#FFC83C" }
    if (this.between(percentage, 60, 80)) { color = "#A4CC65" }
    if (this.between(percentage, 80, 100)) { color = "#4ECF8C" }
    return color
  }

  between(x, min, max) {
    return x >= min && x <= max;
  }
}
