import { Controller } from "stimulus"
import Rails from 'rails-ujs'
import { useDispatch } from 'stimulus-use'
import "summernote"
import 'summernote/dist/summernote-bs4.css';
import flatpickr from 'flatpickr';
import { French } from "flatpickr/dist/l10n/fr.js"
const moment = require('moment')

export default class extends Controller {
  static targets = [
    "subProfileList",
    "portfolioList",
    "datePicker",
    "transactionsTypePicker",
    "transactionsKind",
    "IsDraftInput",
    "transactionsTypeHeader",
    "endDate",
    "lastNavDate",
    "pendingOrDraft",
    "inventoryDate",
    "inventoryTitle",
    "inventory",
    "inventoryButtons",
    "submit",
    "submitIntel",
    "totalPercentage",
    "compositionPercentage",
    "portfolioCheckbox",
    "newAmount",
    "currentAmount",
    "newPortfolioAmount",
    "targetQuotePart",
    "targetCategoryPercentage",
    "newCategoryAmount",
    "breakdownDivider",
    "eosBreakdownTitle",
    "eosBreakdownTable",
    "assetAllocationTable",
    "assetAllocationTitle",
    "assetAllocation",
    "newAssetAllocation",
    "equityAllocationTable",
    "equityAllocationTitle",
    "equityCapitalisationTable",
    "equityCapitalisationTitle",
    "equitySectorTitle",
    "equitySectorTable",
    "currenciesTitle",
    "currenciesTable",
    "currenciesPercentage",
    "newCurrenciesAllocation",
    "comparedCurrenciesAllocation",
    "newEosCategoryBreakdown",
    "assetAllocationPercentage",
    "equityAllocationPercentage",
    "newEquityAllocation",
    "equityCapitalisationPercentage",
    "newEquityCapitalisationAllocation",
    "comparedEosCategoryBreakdown",
    "newPortfolioAmountTarget",
    "currentEosCategoryBreakdown",
    "comparedAssetBreakdown",
    "comparedPortfolio",
    "comparedPortfolioTitle",
    "comparedEquityAllocation",
    "comparedCapitalisationAllocation",
    "sharesBreakdownUpdateTitle",
    "sharesBreakdownUpdateTable",
    "shareBreakdownRows",
    "transactionsTypeHeaderPercentage",
    "transactionAmountCell",
    "transactionPercentCell",
    "balance",
    'balanceValue',
    "currentPortfolioAmount",
    "transactionPercentageInput",
    "transactionsType",
    "breakdownsWarning",
    "share",
    "category",
    "eosCategoryIdBreakdown",
    "intel",
    "draftTransactions",
    "draftTransaction",
    "draftInput",
    "loadingDraft",
    "loadingDraftShares",
    "transactionAmountInput",
    "shares"
  ]

  static values = {
    id: Number
  }

  connect() {
    useDispatch(this)

    $('.summernote').summernote({
      height: 300,
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['para', ['ul', 'ol']]
      ],
      popover: {
        link: [
          ['link', ['linkDialogShow', 'unlink']]
        ]
      }
    })
  }

  displayForm(event) {
    event.preventDefault()
    // Display all tables on page
    if (event.detail) { 
      this.getLastNavDate(event.detail)
    } else {
      this.getLastNavDate()
    }
    this.displayDatePicker()
    this.displayTransactionsTypePicker()
    this.displayIsDraftInput()
  }
  
  submitCompositionForm(event) { 
    event.preventDefault()
    let date = this.getDateFromPicker()
    date = this.lastAvailableDate(date)
    this.setInventoryDate(date)
    let isDraft = this.draftInputTarget.value == 'true'
    this.setInventory(date, {}, isDraft)
    this.hideElement(this.balanceTarget)
  }

  lastAvailableDate(date) {
    let lastNavDate = new Date(this.lastNavDateTarget.dataset.date)
    if (date > lastNavDate) { date = lastNavDate }
    return date
  }

  comparePortfolio(event) {
    event.preventDefault()
    let portfolioId = event.target.dataset.portfolioId
    this.setComparedCategories(portfolioId)
    this.setComparedAssetAllocation(portfolioId)
    this.setComparedEquityAllocation(portfolioId)
    this.setComparedCapitalisationAllocation(portfolioId)
    this.setComparedCurrenciesAllocation(portfolioId)
  }

  setQuoteParts(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    var shareId = event.detail.shareId
    var categoryId = event.detail.categoryId
    let newAmounts = this.newAmountTargets

    if (event.detail.destroy == true) { this.removeShareLine(shareId, categoryId) }
      
    let sumNewAmounts = newAmounts.reduce(function(sum, input) {
      return sum + Number(input.value)
    }, 0.0)
    
    let checkPtfAmount = this.checkPortfolioAmount(sumNewAmounts)

    if (checkPtfAmount) return false
    
    this.processNewTotalPortfolio(sumNewAmounts)
    // mettre à jour la quote part de chaque position
    let targetQuoteParts = this.targetQuotePartTargets
    let targetCategoryPercentages = this.targetCategoryPercentageTargets
    let shareAmount = 0.0
    let quotePart = 0.0
    
    targetQuoteParts.forEach((targetQuotePart, index) => {
      shareAmount = Number(newAmounts[index].value)
      quotePart = (shareAmount / sumNewAmounts) * 100
      targetQuotePart.dataset.targetQuotePart = quotePart
      targetQuotePart.innerHTML = this.percentageElement(quotePart)
    })
    // Mettre à jour la quote part de chaque catégorie
    let newEosCategoryBreakdowns = this.newEosCategoryBreakdownTargets // eosBreakdownTable --> delayer si draft

    targetCategoryPercentages.forEach((targetCategoryQuotePart, index) => {
      
      let categoryId = targetCategoryQuotePart.dataset.categoryId
      let sumCategoryShares = newAmounts.reduce(function(sum, input) {
        if (input.dataset.categoryId == categoryId) {
          return sum + Number(input.value)
        } else {
          return sum
        }
      }, 0.0)
      let newCategoryQuotePart = sumCategoryShares / sumNewAmounts
      let currentCategoryPercentage = targetCategoryQuotePart.dataset.categoryPercentage
      let changeCategoryPercentage = newCategoryQuotePart - currentCategoryPercentage

      this.percentageAndChangeElement(targetCategoryQuotePart, newCategoryQuotePart * 100, changeCategoryPercentage * 100)
      // MAJ eos-breakdown-table (colonne Après arbitrage)
      let newEosCategoryBreakdown = newEosCategoryBreakdowns.find((element) => 
      element.dataset.categoryId == categoryId
      )

      if (typeof newEosCategoryBreakdown !== 'undefined') {

      } else {
        let category = new Object()
        category.category_id = targetCategoryQuotePart.dataset.categoryId
        category.name = targetCategoryQuotePart.dataset.name
        category.quote_part = 0.0
        this.addEosCategory(category)
        newEosCategoryBreakdowns = this.newEosCategoryBreakdownTargets
        newEosCategoryBreakdown = newEosCategoryBreakdowns.find((element) => 
          element.dataset.categoryId == categoryId
        )
      }

      this.percentageAndChangeElement(newEosCategoryBreakdown, newCategoryQuotePart * 100, changeCategoryPercentage * 100)
      newEosCategoryBreakdown.dataset.quotePart = newCategoryQuotePart
      // -------------------------------------
      this.hideElement(this.loadingDraftTarget)
    })
    // MAJ assetAllocationTable (colonne Après arbitrage)
    let comparedPtf = document.querySelector('#compared-portfolio-title')

    if (comparedPtf != 'undefined' && comparedPtf != null) { this.setChangeComparedEosBreakdown() }
    this.setNewAssetAllocation()
    this.setNewEquityAllocation()    
    this.setNewCapitalisationAllocation()
    this.setNewCurrenciesAllocation()
    this.setsharesUpdate()
  }

  removeShareLine(shareId, categoryId) {
    let shares = this.shareTargets
    shares.forEach((shareLine, index) => {
      if (shareLine.dataset.shareId == shareId) { shareLine.remove() }
    })
    
    shares = this.shareTargets
    let categoryShares = shares.filter( shareLine => shareLine.dataset.categoryId == categoryId)
    
    if (categoryShares.length === 0) {
      let categories = this.categoryTargets
      let category = categories.find( categoryLine => categoryLine.dataset.categoryId == categoryId )
      category.remove()
    }
    // Supprimer également dans le tableau eos categories ?? Ça peut poser problème avec une catégorie qui serait uniquement dans le portefeuille comparé
    // this.removeCategoryFromEosBreakdown(categoryId)
  }

  setChangeComparedEosBreakdown() {
    let newEosCategoryBreakdowns = this.newEosCategoryBreakdownTargets
    
    let comparedEosCategoryBreakdowns = this.comparedEosCategoryBreakdownTargets
    let comparedValues = comparedEosCategoryBreakdowns.map(function(comparedEosCategoryBreakdown) {
      return comparedEosCategoryBreakdown.dataset.quotePart
    })
    let uniqueValues = [...new Set(comparedValues)]
    if (uniqueValues.length == 1 ) { return false }

    comparedEosCategoryBreakdowns.forEach((comparedEosCategoryBreakdown, index) => {
      let newEosCategoryBreakdown = newEosCategoryBreakdowns.find((element) => 
        element.dataset.categoryId == comparedEosCategoryBreakdown.dataset.categoryId
      )
      let newValue = newEosCategoryBreakdown.dataset.quotePart
      let comparedValue = comparedEosCategoryBreakdown.dataset.quotePart
      let change = comparedValue - newValue

      this.percentageAndChangeElement(comparedEosCategoryBreakdown, comparedValue * 100, change * 100)
    })
  }

  setNewAssetAllocation() {
    let _this = this
    let date = this.getDateFromPicker()
    date = this.lastAvailableDate(date)
    let inventoryDate = this.dateStringFormat(date)
    let portfolioId = this.portfolioListTarget.value
    let kind = "asset"
    let url = `/admin/compositions/${portfolioId}/artificial_breakdown?end=${inventoryDate}&kind=${kind}`

    let newQuoteParts= this.targetQuotePartTargets
  
    var shares = newQuoteParts.map(function(newQuotePart) {
      return [newQuotePart.dataset.shareId, newQuotePart.dataset.targetQuotePart]
    })

    let data = { shares }

    Rails.ajax({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      type: "GET",
      url: url,
      dataType: "json",
      data : this.toQueryString(data),
      success: (data) => {
        _this.breakdownsWarningTargets.forEach(breakdownsWarningTarget =>
          _this.hideElement(breakdownsWarningTarget)
        )
        _this.replaceAssetAllocation(data)
      },
      error: function(xhr, status, error){
        _this.breakdownsWarningTargets.forEach(breakdownsWarningTarget =>
          _this.showElement(breakdownsWarningTarget)
        )
      }
    })
  }

  replaceAssetAllocation(data) {
    let newAssetAllocations = this.newAssetAllocationTargets
    let assetAllocationPercentages = this.assetAllocationPercentageTargets

    data.forEach((breakdown, index) => {

      let newAssetAllocation = newAssetAllocations.find((element) =>
      element.dataset.assetKind == breakdown.slug
      )
      
      let assetAllocationPercentage = assetAllocationPercentages.find((element) =>
      element.dataset.assetKind == breakdown.slug
      )

      let current = assetAllocationPercentage.dataset.assetPercentage
      let newValue = breakdown.y / 100
      let change = newValue - current
      newAssetAllocation.dataset.quotePart = newValue
      this.percentageAndChangeElement(newAssetAllocation, newValue * 100, change * 100)
    })
    this.setChangeComparedAssetBreakdown(this.newAssetAllocationTargets, this.comparedAssetBreakdownTargets)
  }

  setNewEquityAllocation() {
    let _this = this
    let date = this.getDateFromPicker()
    date = this.lastAvailableDate(date)
    let inventoryDate = this.dateStringFormat(date)
    let portfolioId = this.portfolioListTarget.value
    let kind = "equity_country"
    let url = `/admin/compositions/${portfolioId}/artificial_breakdown?end=${inventoryDate}&kind=${kind}`

    let newQuoteParts= this.targetQuotePartTargets
  
    var shares = newQuoteParts.map(function(newQuotePart) {
      return [newQuotePart.dataset.shareId, newQuotePart.dataset.targetQuotePart]
    })

    let data = { shares }

    Rails.ajax({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      type: "GET",
      url: url,
      dataType: "json",
      data : this.toQueryString(data),
      success: (data) => {
        _this.replaceEquityAllocation(data)
      }
    })
  }

  replaceEquityAllocation(data) {
    let newEquityAllocations = this.newEquityAllocationTargets
    let equityAllocationPercentages = this.equityAllocationPercentageTargets

    data.forEach((breakdown, index) => {

      let newEquityAllocation = newEquityAllocations.find((element) =>
      element.dataset.assetKind == breakdown.slug
      )
      let equityAllocationPercentage = equityAllocationPercentages.find((element) =>
      element.dataset.assetKind == breakdown.slug
      )

      let current = equityAllocationPercentage.dataset.assetPercentage
      let newValue = breakdown.y / 100
      let change = newValue - current
      newEquityAllocation.dataset.quotePart = newValue

      this.percentageAndChangeElement(newEquityAllocation, newValue * 100, change * 100)
    })
    this.setChangeComparedAssetBreakdown(this.newEquityAllocationTargets, this.comparedEquityAllocationTargets)
  }

  setNewCapitalisationAllocation() {
    let _this = this
    let date = this.getDateFromPicker()
    date = this.lastAvailableDate(date)
    let inventoryDate = this.dateStringFormat(date)
    let portfolioId = this.portfolioListTarget.value
    let kind = "equity_capitalisation"
    let url = `/admin/compositions/${portfolioId}/artificial_breakdown?end=${inventoryDate}&kind=${kind}`

    let newQuoteParts= this.targetQuotePartTargets
  
    var shares = newQuoteParts.map(function(newQuotePart) {
      return [newQuotePart.dataset.shareId, newQuotePart.dataset.targetQuotePart]
    })

    let data = { shares }

    Rails.ajax({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      type: "GET",
      url: url,
      dataType: "json",
      data : this.toQueryString(data),
      success: (data) => {
        _this.replaceEquityCapitalisationAllocation(data)
      }
    })
  }

  replaceEquityCapitalisationAllocation(data) {
    let newEquityCapitalisationAllocations = this.newEquityCapitalisationAllocationTargets
    let equityCapitalisationPercentages = this.equityCapitalisationPercentageTargets
    data.forEach((breakdown, index) => {

      let newEquityCapitalisationAllocation = newEquityCapitalisationAllocations.find((element) =>
      element.dataset.assetKind == breakdown.slug
      )
      let equityCapitalisationPercentage = equityCapitalisationPercentages.find((element) =>
      element.dataset.assetKind == breakdown.slug
      )

      let current = equityCapitalisationPercentage.dataset.assetPercentage
      let newValue = breakdown.y / 100
      let change = newValue - current
      newEquityCapitalisationAllocation.dataset.quotePart = newValue
      this.percentageAndChangeElement(newEquityCapitalisationAllocation, newValue * 100, change * 100)
    })
    this.setChangeComparedAssetBreakdown(this.newEquityCapitalisationAllocationTargets, this.comparedCapitalisationAllocationTargets)
  }

  setNewCurrenciesAllocation() {
    let _this = this
    let date = this.getDateFromPicker()
    date = this.lastAvailableDate(date)
    let inventoryDate = this.dateStringFormat(date)
    let portfolioId = this.portfolioListTarget.value
    let kind = "currencies"
    let url = `/admin/compositions/${portfolioId}/artificial_breakdown?end=${inventoryDate}&kind=${kind}`

    let newQuoteParts= this.targetQuotePartTargets

    var shares = newQuoteParts.map(function(newQuotePart) {
      return [newQuotePart.dataset.shareId, newQuotePart.dataset.targetQuotePart]
    })

    let data = { shares }

    Rails.ajax({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      type: "GET",
      url: url,
      dataType: "json",
      data : this.toQueryString(data),
      success: (data) => {
        _this.replaceCurrenciesAllocation(data)
      }
    })
  }

  replaceCurrenciesAllocation(data) {
    let newCurrenciesAllocations = this.newCurrenciesAllocationTargets
    let currenciesPercentages = this.currenciesPercentageTargets
    data.forEach((breakdown, index) => {

      let newCurrenciesAllocation = newCurrenciesAllocations.find((element) =>
      element.dataset.assetKind == breakdown.slug
      )
      let currenciesPercentage = currenciesPercentages.find((element) =>
      element.dataset.assetKind == breakdown.slug
      )

      let current = currenciesPercentage.dataset.assetPercentage
      let newValue = breakdown.y / 100
      let change = newValue - current
      newCurrenciesAllocation.dataset.quotePart = newValue
      this.percentageAndChangeElement(newCurrenciesAllocation, newValue * 100, change * 100)
    })
    this.setChangeComparedAssetBreakdown(this.newCurrenciesAllocationTargets, this.comparedCurrenciesAllocationTargets)
  }

  setComparedCategories(portfolioId) {
    let _this = this
    let date = this.getDateFromPicker()
    date = this.lastAvailableDate(date)
    let inventoryDate = this.dateStringFormat(date)
    let url = `/admin/compositions/${portfolioId}/eos_breakdown_portfolio?end=${inventoryDate}`

    let data = {  }

    Rails.ajax({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      type: "GET",
      url: url,
      dataType: "json",
      data : this.toQueryString(data),
      success: (data) => {
        _this.setComparedCategoriesInventory(data)
      }
    })
  }

  // setInventoryCompared() {
  //   let comparedInventoryCategoryColumns = this.comparedInventoryCategoryColumnTargets
  //   let newEosCategoryBreakdowns = this.newEosCategoryBreakdownTargets
  //   let comparedEosCategoryBreakdowns = this.comparedEosCategoryBreakdownTargets

  //   comparedInventoryCategoryColumns.forEach((element, index) => {
  //     element.innerHTML = "-"
  //     let categoryId = element.dataset.categoryId
  //     let newEosCategoryBreakdown = newEosCategoryBreakdowns.find((el) => 
  //     el.dataset.categoryId == categoryId)
  //     let comparedEosCategoryBreakdown = comparedEosCategoryBreakdowns.find((el) => 
  //     el.dataset.categoryId == categoryId)

  //     let newArbitrageValue = newEosCategoryBreakdown.dataset.quotePart
  //     let comparedPortfolioValue = comparedEosCategoryBreakdown.dataset.quotePart
  //     let change = comparedPortfolioValue - newArbitrageValue
      
  //     this.percentageAndChangeElement(element, comparedPortfolioValue * 100, change * 100)
  //   })

  // }

  setComparedCategoriesInventory(data) {
    let portfolio = data.portfolio
    this.setComparedPortfolioTitle(portfolio)
    let dataShares = data.shares
    let comparedEosCategoryBreakdowns = this.comparedEosCategoryBreakdownTargets
    let newEosCategoryBreakdowns = this.newEosCategoryBreakdownTargets
    
    comparedEosCategoryBreakdowns.forEach((categoryBreakdown, index) => {
      categoryBreakdown.innerHTML = this.percentageElement(0.0)
    })
    
    this.createMissingEosCategoriesBreakdown(dataShares, newEosCategoryBreakdowns)

    comparedEosCategoryBreakdowns = this.comparedEosCategoryBreakdownTargets
    newEosCategoryBreakdowns = this.newEosCategoryBreakdownTargets

    comparedEosCategoryBreakdowns.forEach((eosCategory, index) => {
      eosCategory.dataset.quotePart = 0.0
    })

    dataShares.forEach((eosCategory, index) => {
      let categoryId = eosCategory.category_id
      let comparedEosCategoryBreakdown = comparedEosCategoryBreakdowns.find((element) => 
        element.dataset.categoryId == categoryId
      )

      if (typeof comparedEosCategoryBreakdown !== 'undefined') {
        let newEosCategoryBreakdown = newEosCategoryBreakdowns.find((element) => 
          element.dataset.categoryId == categoryId
        )
        let newQuotePart = newEosCategoryBreakdown.dataset.quotePart
        let change = eosCategory.quote_part - newQuotePart
        comparedEosCategoryBreakdown.dataset.quotePart = eosCategory.quote_part
        this.percentageAndChangeElement(comparedEosCategoryBreakdown, eosCategory.quote_part * 100, change * 100)

      } else {
        // Créer la catégorie si elle n'existe pas
        this.addEosCategory(eosCategory)
      }
    })
    // this.setInventoryCompared() // calculer les changements dans l'inventaire
  }

  createMissingEosCategoriesBreakdown(data, postArbitrageBreakdowns) {
    data.forEach((eosCategory, index) => {
      let categoryId = eosCategory.category_id
      let postArbitrageBreakdown = postArbitrageBreakdowns.find((element) => 
        element.dataset.categoryId == categoryId
      )
      if (typeof postArbitrageBreakdown !== 'undefined') {} 
      else {
        this.addEosCategory(eosCategory)
      }
    })
  }

  addEosCategory(category) {
    let lastEosBreakdownLine = document.querySelector('#eos-breakdown-list')
    let data = this.eos_category_element(category, 0.0)
    lastEosBreakdownLine.insertAdjacentHTML('beforeend', data)
  }

  setComparedAssetAllocation(portfolioId) {
    let _this = this
    let date = this.getDateFromPicker()
    let inventoryDate = this.dateStringFormat(date)
    let kind = "asset"
    let url = `/admin/compositions/${portfolioId}/asset_breakdown_data?end=${inventoryDate}&kind=${kind}`

    let data = { }

    Rails.ajax({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      type: "GET",
      url: url,
      dataType: "json",
      data : this.toQueryString(data),
      success: (data) => {
        let comparedAssetBreakdowns = _this.comparedAssetBreakdownTargets
        let newAssetAllocations = _this.newAssetAllocationTargets        
        _this.setComparedAssetInventory(data, _this, comparedAssetBreakdowns, newAssetAllocations)
      }
    })
  }

  setComparedEquityAllocation(portfolioId) {
    let _this = this
    let date = this.getDateFromPicker()
    let inventoryDate = this.dateStringFormat(date)
    let kind = "equity_country"
    let url = `/admin/compositions/${portfolioId}/asset_breakdown_data?end=${inventoryDate}&kind=${kind}`

    let data = { }

    Rails.ajax({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      type: "GET",
      url: url,
      dataType: "json",
      data : this.toQueryString(data),
      success: (data) => {
        let newEquityAllocations = _this.newEquityAllocationTargets
        let comparedEquityAllocations = _this.comparedEquityAllocationTargets
        _this.setComparedAssetInventory(data, _this, comparedEquityAllocations, newEquityAllocations)
      }
    })
  }

  setComparedCapitalisationAllocation(portfolioId) {
    let _this = this
    let date = this.getDateFromPicker()
    let inventoryDate = this.dateStringFormat(date)
    let kind = "equity_capitalisation"
    let url = `/admin/compositions/${portfolioId}/asset_breakdown_data?end=${inventoryDate}&kind=${kind}`

    let data = { }

    Rails.ajax({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      type: "GET",
      url: url,
      dataType: "json",
      data : this.toQueryString(data),
      success: (data) => {
        let newEquityCapitalisationAllocations = _this.newEquityCapitalisationAllocationTargets
        let comparedCapitalisationAllocations = _this.comparedCapitalisationAllocationTargets
        _this.setComparedAssetInventory(data, _this, comparedCapitalisationAllocations, newEquityCapitalisationAllocations)
      }
    })
  }

  setComparedCurrenciesAllocation(portfolioId) {
    let _this = this
    let date = this.getDateFromPicker()
    let inventoryDate = this.dateStringFormat(date)
    let kind = "currencies"
    let url = `/admin/compositions/${portfolioId}/asset_breakdown_data?end=${inventoryDate}&kind=${kind}`

    let data = { }

    Rails.ajax({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      type: "GET",
      url: url,
      dataType: "json",
      data : this.toQueryString(data),
      success: (data) => {
        let newCurrenciesAllocations = _this.newCurrenciesAllocationTargets
        let comparedCurrenciesAllocations = _this.comparedCurrenciesAllocationTargets
        _this.setComparedAssetInventory(data, _this, comparedCurrenciesAllocations, newCurrenciesAllocations)
      }
    })
  }

  setComparedAssetInventory(data, _this, comparedBreakdowns, newAllocations) {
    data.forEach(function(breakdown){
      let comparedBreakdown = comparedBreakdowns.find((element) => 
        element.dataset.assetKind == breakdown.slug
      )
      let newAllocation = newAllocations.find((element) => 
        element.dataset.assetKind == breakdown.slug
      )
      let newValue = newAllocation.dataset.quotePart
      let comparedValue = breakdown.y / 100
      let change = comparedValue - newValue

      comparedBreakdown.dataset.quotePart = comparedValue

      _this.percentageAndChangeElement(comparedBreakdown, comparedValue * 100, change * 100)
    })
  }

  setChangeComparedAssetBreakdown(newAllocations, comparedBreakdowns) {
    
    let comparedValues = comparedBreakdowns.map(function(comparedBreakdown) {
      return comparedBreakdown.dataset.quotePart
    })
    let uniqueValues = [...new Set(comparedValues)]
    if (uniqueValues.length == 1 ) { return false }

    comparedBreakdowns.forEach((comparedBreakdown, index) => {
      let newAllocation = newAllocations.find((element) => 
        element.dataset.assetKind == comparedBreakdown.dataset.assetKind
      )
      let newValue = newAllocation.dataset.quotePart
      let comparedValue = comparedBreakdown.dataset.quotePart
      let change = comparedValue - newValue

      this.percentageAndChangeElement(comparedBreakdown, comparedValue * 100, change * 100)
    })
  }

  toQueryString(data) {
    return Object.keys(data).map(key => key + '=' + data[key]).join('&')
  }

  percentageElement(value) {
    if (value != 0.0) {
      return `${value.toFixed(2).toString().split(".").join(",")}&nbsp;%`
    } else {
      return `-`
    }
  }

  balanceElement(value) {
    if (value != 0.0) {
      return `<strong class="badge badge-error">
                ${value.toFixed(2).toString().split(".").join(",")}&nbsp;%
              </strong>
              `
    } else {
      return `${'✅'}`
    }
  }

  percentageAndChangeElement(el, value, changeValue) {
    el.innerHTML = this.percentageElement(value)
    this.insertChangeValue(el, changeValue)
  }

  insertChangeValue(el, value) {
    if (Math.abs(value) < 0.01) {
      
    } else {
      let change = value
      let color = ''
      if (value > 0) {
        change = `+${value.toFixed(2).toString().split(".").join(",")}`
        color = "#4ECF8C"
      } else if (value < 0) {
        change = value.toFixed(2).toString().split(".").join(",")
        color = "#FF684B"
      } else {}

      el.insertAdjacentHTML('beforeend', `<span><small>&nbsp;(${change}&nbsp;%)</small></span>`)
      el.lastChild.style.color = color
    }
  }

  processNewTotalPortfolio(sumNewAmounts) {
    this.newPortfolioAmountTarget.dataset.newPortfolioAmount = sumNewAmounts
    this.newPortfolioAmountTarget.innerHTML = `${this.numberWithSpacesHtml(sumNewAmounts.toFixed(2))}&nbsp;€`
    this.setBalance(sumNewAmounts)
  }

  setBalance(newPortfolioValue) {
    let transactionPercentageInputs = this.transactionPercentageInputTargets
    let transactionsTypes = this.transactionsTypeTargets
    let sum = 0

    let checkValues = transactionPercentageInputs.map(function(transactionPercentageInput) {
      return Number(transactionPercentageInput.value)
    })

    let uniqueValues = [...new Set(checkValues)]
    if (uniqueValues.length == 1 ) { 
      this.balanceValueTarget.innerHTML = this.balanceElement(0.0)
      return false
    }

    transactionPercentageInputs.forEach((transactionPercentageInput, index) => {
      if (transactionsTypes[index].value == "1" ) {
        var value = parseInt(Math.round(Number(transactionPercentageInput.value) * 100))
      } else if (transactionsTypes[index].value == "2" || transactionsTypes[index].value == "3" ) {
        var value = parseInt(Math.round(-Number(transactionPercentageInput.value) * 100 ))
      } else {
        value = 0
      }
      sum = sum + value
    })

    // this.balanceValueTarget.innerHTML = this.percentageElement(sum)
    this.balanceValueTarget.innerHTML = this.balanceElement(sum / 100)
    this.showElement(this.balanceTarget)

    if (sum == 0.0) {
      this.hideElement(this.submitIntelTarget)
      this.showElement(this.submitTarget)
    }
    else {
      this.showElement(this.submitIntelTarget)
      this.hideElement(this.submitTarget)
    }

  }

  checkPortfolioAmount(sumNewAmounts) {
    return this.newPortfolioAmountTarget.dataset.newPortfolioAmount == sumNewAmounts
  }

  setSubProfilesList(event) {
    var profileId = event.target.value
    Rails.ajax({
      type: "GET",
      url: "/admin/profiles/" + profileId + "/sub_profiles",
      success: (data) => {
        var subProfileList = this.subProfileListTarget
        subProfileList.disabled = false
        var child = subProfileList.lastElementChild
        while (child) {
          subProfileList.removeChild(child)
          child = subProfileList.lastElementChild
        }

        var option = document.createElement('option')
        option.appendChild( document.createTextNode("-- Choisir un sous profil --") )
        subProfileList.appendChild(option)

        data.forEach(function(subProfile){
          var option = document.createElement('option')
          option.appendChild( document.createTextNode(subProfile.name) )
          option.value = subProfile.id
          subProfileList.appendChild(option)
        })
      }
    })
  }

  setPortfoliosList(event) {
    event.preventDefault()
    this.hideDatePicker()

    var subProfileId = event.target.value
    Rails.ajax({
      type: "GET",
      url: "/admin/sub_profiles/" + subProfileId + "/portfolios",
      success: (data) => {
        var portfolioList = this.portfolioListTarget
        portfolioList.disabled = false
        var child = portfolioList.lastElementChild
        while (child) {
          portfolioList.removeChild(child)
          child = portfolioList.lastElementChild
        }

        var option = document.createElement('option')
        option.appendChild( document.createTextNode("-- Choisir un portefeuille --") )
        portfolioList.appendChild(option)

        data.forEach(function(portfolio){
          var option = document.createElement('option')
          option.appendChild( document.createTextNode(portfolio.name) )
          option.value = portfolio.id
          portfolioList.appendChild(option)
        })
      }
    })
  }
  
  setEosCategoryBreakdown(date, details) {
    let _this = this
    let portfolioId = this.portfolioListTarget.value
    let inventoryDate = this.dateStringFormat(date)
    let url = `/admin/compositions/${portfolioId}/eos_categories_breakdown?end=${inventoryDate}`

    _this.showLoadingElements(_this.eosBreakdownTitleTarget, _this.eosBreakdownTableTarget)

    Rails.ajax({
      type: "GET",
      url: url,
      success: (data) => {    
        _this.showElements(this.breakdownDividerTargets)
        _this.eosBreakdownTableTarget.innerHTML = data.html
        _this.hideLoadingElements(_this.eosBreakdownTableTarget)
        if (details && details.kind == 'draft') {
          _this.showElement(this.loadingDraftTarget)
          _this.setDraftTransactions()
        }
      }
    })
  }

  setAssetAllocation(date) {
    let _this = this
    let portfolioId = this.portfolioListTarget.value
    let inventoryDate = this.dateStringFormat(date)
    let url = `/admin/compositions/${portfolioId}/asset_breakdown?end=${inventoryDate}`

    this.showLoadingElements(this.assetAllocationTitleTarget, this.assetAllocationTableTarget)

    Rails.ajax({
      type: "GET",
      url: url,
      success: (data) => {
        _this.assetAllocationTableTarget.innerHTML = data.html
        _this.hideLoadingElements(_this.assetAllocationTableTarget)
      }
    })
  }

  setEquityAllocation(date) {
    let _this = this
    let portfolioId = this.portfolioListTarget.value
    let inventoryDate = this.dateStringFormat(date)
    let url = `/admin/compositions/${portfolioId}/equity_allocation?end=${inventoryDate}`

    this.showLoadingElements(this.equityAllocationTitleTarget, this.equityAllocationTableTarget)

    Rails.ajax({
      type: "GET",
      url: url,
      success: (data) => {
        _this.equityAllocationTableTarget.innerHTML = data.html
        _this.hideLoadingElements(_this.equityAllocationTableTarget)
      }
    })
  }

  setEquityCapitalisation(date) {
    let _this = this
    let portfolioId = this.portfolioListTarget.value
    let inventoryDate = this.dateStringFormat(date)
    let url = `/admin/compositions/${portfolioId}/equity_capitalisation_allocation?end=${inventoryDate}`

    this.showLoadingElements(this.equityCapitalisationTitleTarget, this.equityCapitalisationTableTarget)

    Rails.ajax({
      type: "GET",
      url: url,
      success: (data) => {
        _this.equityCapitalisationTableTarget.innerHTML = data.html
        _this.hideLoadingElements(_this.equityCapitalisationTableTarget)
      }
    })
  }

  setCurrencyCapitalisation(date) {
    let _this = this
    let portfolioId = this.portfolioListTarget.value
    let inventoryDate = this.dateStringFormat(date)
    let url = `/admin/compositions/${portfolioId}/currencies_allocation?end=${inventoryDate}`

    this.showLoadingElements(this.currenciesTitleTarget, this.currenciesTableTarget)

    Rails.ajax({
      type: "GET",
      url: url,
      success: (data) => {
        _this.currenciesTableTarget.innerHTML = data.html
        _this.hideLoadingElements(_this.currenciesTableTarget)
      }
    })
  }

  setsharesUpdate() {
    let _this = this
    let sharesBreakdownUpdateTable = this.sharesBreakdownUpdateTableTarget
    let portfolioId = this.portfolioListTarget.value
    let url = `/admin/compositions/${portfolioId}/shares_breakdown_update`
    let targetQuoteParts = this.targetQuotePartTargets

    let share_ids = targetQuoteParts.map(function(newQuotePart) {
      return newQuotePart.dataset.shareId
    })

    let data = { share_ids }

    this.showLoadingElements(this.sharesBreakdownUpdateTitleTarget, sharesBreakdownUpdateTable)

    Rails.ajax({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      type: "GET",
      url: url,
      dataType: "json",
      data : this.toQueryString(data),
      success: (data) => {
        _this.setSharesBreakdownUpdateTable(data)
        _this.hideLoadingElements(sharesBreakdownUpdateTable)
      }
    })

  }

  // Désactivé
  // setEquitySector(date) {
  //   let _this = this
  //   this.showLoading(this.equitySectorTableTarget)
  //   let portfolioId = this.portfolioListTarget.value
  //   let inventoryDate = this.dateStringFormat(date)
  //   let url = `/admin/compositions/${portfolioId}/equity_sector_allocation?end=${inventoryDate}`

  //   Rails.ajax({
  //     type: "GET",
  //     url: url,
  //     success: (data) => {
  //       _this.showElement(_this.equitySectorTitleTarget)
  //       _this.equitySectorTableTarget.innerHTML = data.html
  //       _this.hideLoading(_this.equitySectorTableTarget)
  //     }
  //   })
  // }

  showElements(elements) {
    elements.forEach((element, index) => {
      this.showElement(element)
    })
  }

  getLastNavDate(details) {
    let _this = this
    let portfolioId = this.portfolioListTarget.value
    let last_url = `/admin/portfolios/${portfolioId}/last_nav`
    Rails.ajax({
      type: "GET",
      url: last_url,
      success: (data) => {
        let last_nav_date = new Date(data.last_nav_date)
        _this.displayLastNavDate(last_nav_date)

        if (details) {
          var set_date = new Date(details.date)
        } else {
          var set_date = last_nav_date
        }

        _this.setLastNavDate(set_date)

        if (data.pending_or_draft) {
          _this.displayAlertPendingOrDraft()
        } else {
          _this.hideAlertPendingOrDraft()
          _this.setInventory(set_date, details)
        }
      }
    })
  }

  setLastNavDate(date) {
    flatpickr(this.endDateTarget, {
      altInput: true,
      allowInput: true,
      altFormat: 'd/m/Y',
      dateFormat: "Y-m-d",
      locale: French,
      defaultDate: new Date(date)
    })
  }

  getDateFromPicker() {
    let calendar = this.endDateTarget
    let date = new Date(calendar.value)
    return date
  }

  setInventory(date, details, reloading = false) {
    let _this = this
    let portfolioId = this.portfolioListTarget.value
    let inventoryDate = this.dateStringFormat(date)
    let inventory_url = `/admin/compositions/${portfolioId}/inventory?end=${inventoryDate}`
    let transactionsKind = this.transactionsKindTarget.value

    this.showLoading(this.inventoryTarget)
    this.inventoryTarget.classList.add('p-5')
    
    if (details && details.kind == 'draft') { 
      _this.showElement(this.loadingDraftTarget)
    }

    Rails.ajax({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      type: "GET",
      dataType: "json",
      url: inventory_url,
      success: (data) => {
        _this.inventoryTarget.innerHTML = data.html
        _this.setInventoryDate(date)
        _this.setTransactionsTypeHeader(transactionsKind)
        _this.setsharesUpdate()
        _this.setEosCategoryBreakdown(date, details)
        _this.setAssetAllocation(date)
        _this.setEquityAllocation(date)
        _this.setEquityCapitalisation(date)
        _this.setCurrencyCapitalisation(date)
        if (reloading == true) {
          _this.showElement(_this.loadingDraftTarget)
          _this.setDraftTransactions()
        }
        // _this.setEquitySector(date)
        _this.showElement(_this.inventoryTitleTarget)
        _this.showElement(_this.inventoryButtonsTarget)
        _this.hideElement(_this.balanceTarget)
        _this.hideLoading(_this.inventoryTarget)
        _this.inventoryTarget.classList.remove('p-5')
      }
    })
  }

  // Draft Mecanic ------------------------------------------------
  setDraftTransactions() {
    let draftTransactions = this.draftTransactionTargets
    let transactionsTypes = this.transactionsTypeTargets

    draftTransactions.forEach((draftTransaction, index) => {
      var shareId = draftTransaction.dataset.shareId
      var transactionsTypeElement = transactionsTypes.find((element) =>  element.dataset.shareId == shareId)

      this.showElement(this.loadingDraftSharesTarget)

      if (transactionsTypeElement) { // if exist change transactionType value
        setTimeout(() => {
          this.setDraftElements(shareId, draftTransaction.dataset, transactionsTypeElement)
        }, 1200)
      } else { // otherwise create row
        this.buildDraftElements(draftTransaction.dataset)
      }
    })
  }

    // Add rows to inventory -----------------------
  buildDraftElements(draftTransactionDetails) {
    let event = new Event('addShare')
    Object.defineProperty(
      event,
      'target',
      { writable: false, value: draftTransactionDetails })

    this.addShare(event, draftTransactionDetails)
  }

  addShare(event, draftTransactionDetails) {
    let shareId = event.target.shareId
    let eosCategoryId = event.target.eosCategoryId
    let date = this.getDateFromPicker()
    let inventoryDate = this.dateStringFormat(date)    
    let categoryPresence = this.categoryInList(eosCategoryId)

    if (categoryPresence) {
      this.setShareRow(eosCategoryId, shareId, inventoryDate, draftTransactionDetails)
    }
    else {
      this.setcategoryRow(eosCategoryId, shareId, inventoryDate, draftTransactionDetails)
    }
  }

  setcategoryRow(eosCategoryId, shareId, inventoryDate, draftTransactionDetails) {
    let portfolioId = this.portfolioListTarget.value
    let lastInventoryRow = document.querySelector('#inventory')
    let url = `/admin/compositions/${portfolioId}/category_line?share_id=${shareId}&category_id=${eosCategoryId}&date=${inventoryDate}`

    Rails.ajax({
      type: "GET",
      url: url,
      success: (data) => {
        lastInventoryRow.insertAdjacentHTML('beforeend', data.html)

        let transactionsTypes = this.transactionsTypeTargets
        var transactionsTypeElement = transactionsTypes.find((element) =>  element.dataset.shareId == shareId)
        setTimeout(() => {
          this.setDraftElements(shareId, draftTransactionDetails, transactionsTypeElement)
        }, 1200)
      }
    })
  }

  setShareRow(eosCategoryId, shareId, inventoryDate, draftTransactionDetails) {
    let portfolioId = this.portfolioListTarget.value
    let ShareLines = this.categoryShareLines(eosCategoryId)
    let lastShareRow = ShareLines.pop()
    let url = `/admin/compositions/${portfolioId}/share_line?share_id=${shareId}&category_id=${eosCategoryId}&date=${inventoryDate}`
    // url test : '/admin/compositions/255/share_line?share_id=8296&category_id=19&date=2022-08-22'

    Rails.ajax({
      type: "GET",
      url: url,
      success: (data) => {
        lastShareRow.insertAdjacentHTML('afterend', data.html)
        let transactionsTypes = this.transactionsTypeTargets
        var transactionsTypeElement = transactionsTypes.find((element) =>  element.dataset.shareId == shareId)
        setTimeout(() => {
          this.setDraftElements(shareId, draftTransactionDetails, transactionsTypeElement)
        }, 1200)
      }
    })
  }

  categoryShareLines(eosCategoryId) {
    let x = Array.prototype.map.call(
      this.sharesTarget.querySelectorAll('[data-category]'),
      function(item) {
        if (item.dataset.category == eosCategoryId) {
          return item
        }
      }
    ).filter(element => {
      return element !== undefined
    })
    return x
  }

  shareInList(shareId) {
    return this.sharesIds().includes(parseInt(shareId))
  }

  sharesIds() {
    return Array.prototype.map.call(
      this.sharesTarget.querySelectorAll('[data-share]'),
      function(item) {
        return parseInt(item.dataset.share)
      }
    )
  }

  categoryInList(categoryId) {
    return this.categorysIds().includes(parseInt(categoryId))
  }

  categorysIds() {
    return Array.prototype.map.call(
      this.sharesTarget.querySelectorAll('[data-category]'),
      function(item) {
        return parseInt(item.dataset.category)
      }
    )
  }
    // Add rows to inventory -----------------------
  setDraftElements(shareId, draftTransactionDetails, transactionsTypeElement) {
    this.setTransactionsTypeInput(transactionsTypeElement, draftTransactionDetails)
    if (draftTransactionDetails.movement != '3') { 
      this.setTransactionPercentageInput(draftTransactionDetails)
    }
    this.hideElement(this.loadingDraftSharesTarget)
  }

  setTransactionsTypeInput(inputElement, draftTransactionDetails) {
    inputElement.value = draftTransactionDetails.movement
    this.dispatch('setTransactionsTypeInput', {
      type: 'draft',
      categoryId: inputElement.dataset.categoryId,
      shareId: inputElement.dataset.shareId,
    })
  }

  setTransactionPercentageInput(draftTransactionDetails) {
    let transactionPercentageInputs = this.transactionPercentageInputTargets
    let transactionPercentageInput = transactionPercentageInputs.find((element) =>  element.dataset.shareId == draftTransactionDetails.shareId)
    transactionPercentageInput.value = draftTransactionDetails.percentage

    let keyupEvent = new Event('keyup');
    transactionPercentageInput.dispatchEvent(keyupEvent)
  }

  // Draft Mecanic ------------------------------------------------

  showLoadingElements(title, table) {
    this.showElement(title)
    this.showLoading(table)
    table.classList.add('p-5')
  }

  hideLoadingElements(table) {
    table.classList.remove('p-5')
    this.hideLoading(table)
  }

  displayAlertPendingOrDraft() {
    this.pendingOrDraftTarget.innerHTML = this.alertPendingOrDraft()
  }

  hideAlertPendingOrDraft() {
    this.pendingOrDraftTarget.innerHTML = ''
  }

  displayDatePicker() {
    this.datePickerTarget.classList.remove('d-none')
  }
  displayTransactionsTypePicker() {
    this.transactionsTypePickerTarget.classList.remove('d-none')
  }
  displayIsDraftInput() {
    this.IsDraftInputTarget.classList.remove('d-none')
  }
  hideDatePicker() {
    this.datePickerTarget.classList.add('d-none')
  }
  displayLastNavDate(date) {
    date = this.dateStringFormat(date)
    this.lastNavDateTarget.innerHTML = this.lastNavDateElement(date)
    this.lastNavDateTarget.dataset.date = date
  }

  setInventoryDate(date) {
    date = this.dateStringFormat(date)
    this.inventoryDateTarget.textContent = date
  }

  setTransactionsTypeHeader(value) {
    if (value == '0') {
      this.transactionsTypeHeaderTarget.innerHTML = this.transactionsTypeHeaderElement('montant €')
    } else if (value == '1') {
      this.transactionsTypeHeaderTarget.innerHTML = this.transactionsTypeHeaderElement('nb parts')
    }
  }

  // displayInventoryCompared() {
  //   this.showElement(this.comparedInventoryColumnTarget)
  //   let comparedInventoryCategoryColumns = this.comparedInventoryCategoryColumnTargets
  //   comparedInventoryCategoryColumns.forEach((element, index) => {
  //     this.showElement(element.parentElement)
  //   })
  // }
  // removeCategoryFromEosBreakdown(categoryId) {
  //   let categoryLines = this.eosCategoryIdBreakdownTargets
  //   let category = categoryLines.find( categoryLine => categoryLine.dataset.categoryId == categoryId )
  //   category.remove()
  // }
  
  lastNavDateElement(date) {
    return `<div class="px-3 mx-3 py-2">
      Dernière date de valorisation du portefeuille : ${date}
     </div>`
  }

  setComparedPortfolioTitle(portfolio) {
    this.comparedPortfolioTarget.innerHTML = this.comparedPortfolioElement(portfolio)
  }

  comparedPortfolioElement(portfolio) {
    return `
      <div id="compared-portfolio-title"
           class="px-3 mx-3"
           data-compared_portfolio_id="${portfolio.id}"
           data-composition-transaction-target="comparedPortfolioTitle">
            <a target="_blank" href="/portfolios/${portfolio.id}">Portefeuille comparé : ${portfolio.name}</a>
      </div>`
  }

  transactionsTypeHeaderElement(type) {
    return ` Transaction<br>(${type})`
  }

  showLoading(el) {
    el.insertAdjacentHTML('beforeend', this.loadingElement())
  }

  loadingElement() {
    return `
      <div class="loading bg-white d-flex justify-content-center align-items-center">
        <div class="spinner-grow text-secondary" role="status" style="width: 6rem; height: 6rem;">
          <span class="sr-only">Chargement...</span>
        </div>
      </div>
    `
  }

  alertPendingOrDraft() {
    return `
      <div class="alert alert-warning alert-dismissible fade show" role="alert">
        Un arbitrage non terminé existe déjà pour ce portefeuille (status : 'en cours', 'erreur' ou 'brouillon'). Veuillez le supprimer pour en créer un nouveau.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    `
  }

  eos_category_element(category, newEosQuotePart) {
    return `
      <tr data-composition-transaction-target="EosCategoryIdBreakdown"
          data-category-id="${category.category_id}">
        <td class="pr-3">
          ${category.name}
        </td>
        <td class="text-right">
          -
        </td>
        <td class="text-right"
            data-quote-part="${newEosQuotePart}"
            data-category-id="${category.category_id}"
            data-composition-transaction-target="newEosCategoryBreakdown">
          -
        </td>
        <td class="text-right"
            data-category-id="${category.category_id}"
            data-quote-part="${category.quote_part}"
            data-composition-transaction-target="comparedEosCategoryBreakdown">
           ${this.percentageElement(category.quote_part)}
        </td>
    </tr>
    `
  }

  setSharesBreakdownUpdateTable(shareUpdates) {
    this.sharesBreakdownUpdateTableTarget.innerHTML = this.sharesBreakdownUpdateTableElement()

    let shareBreakdownRows = this.shareBreakdownRowsTarget
    shareUpdates['shares'].forEach((share, index) => {
      shareBreakdownRows.insertAdjacentHTML('beforeend', this.sharesBreakdownUpdateRow(share))
    })
  }

  sharesBreakdownUpdateTableElement() {
    return `
      <table id="" class="table mb-3 breakdown">
      <thead>
        <tr class="bg-transparent">
          <th class="px-4">
            Nom part
          </th>
          <th class="px-4">
            Isin
          </th>
          <th class="px-4">
            Mise à jour
          </th>
        </tr>
      </thead>
      <tbody 
        class="border-0"
        data-composition-transaction-target="shareBreakdownRows">
    </table>
    `
  }

  sharesBreakdownUpdateRow(share) {
    return `
        <tr>
          <td class="pr-3">
            <a target="_blank" href="/shares/${share.id}">${share.name}</a>
          </td>
          <td class="text-center">
          <small class="ml-2 text-muted">
            ${share.isin}
          </small>
          </td>
          <td class="text-right">${share.date}</td>
        </tr>
    `
  }

  hideLoading(el) {
    if (el.querySelector('.loading')) {
      el.querySelector('.loading').remove()
    }
  }

  dateStringFormat(date) {
    return date.toISOString().split('T')[0]
  }

  hideElement(el) {
    el.classList.add('d-none')
  }

  showElement(el) {
    el.classList.remove('d-none')
  }

  numberWithSpacesHtml(x) {
    var parts = x.toString().split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;")
    return parts.join(",")
  }

}
