import { Controller } from "stimulus"
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    'portfolioPercentage',
    'sharePercentage',
    'keep',
    'keepInput',
    'complete',
    'completeInput',
    'action'
  ]

  connect() {
    this.processPortfolioPercentage()
    this.enableKeepIfQuotePart()

    this.element.addEventListener('keepCategoryPercentage', (event) => {
      this.keep(event)
    })

    this.element.addEventListener('completeCategoryPercentage', (event) => {
      this.enableKeepIfQuotePart()
    })
  }

  processPortfolioPercentage(event) {
    let sharePercentageInCategory = this.sharePercentageTarget.value
    let categoryPercentageInPortfolio = this.data.get("percentage")
    let portfolioPercentage = (sharePercentageInCategory * categoryPercentageInPortfolio) / 100
    this.portfolioPercentageTarget.innerHTML = `${parseFloat(portfolioPercentage.toFixed(8))}%`
  }

  enableKeepIfQuotePart() {
    let quotePart = parseFloat(this.data.get('quote-part'))
    if (quotePart) {
      let total = this.portfolioPercentage()
      let newValue = quotePart * (1 / total)
      this.element.dataset.percentageKeepCategory = newValue
      if (this.keepTarget) {
        this.keepTarget.classList.remove('d-none')
        this.keepTarget.innerText = `Conserver (${quotePart.toFixed(2)}%)`
      }
    }
  }

  keep(event) {
    event.preventDefault()
    let quotePart = parseFloat(this.data.get('quote-part'))
    let total = this.portfolioPercentage()
    let newValue = quotePart * (1 / total)
    // let newValue = parseFloat(this.element.dataset.percentageKeepCategory)

    this.completeTarget.remove()
    this.keepTarget.remove()
    this.keepInputTarget.value = 1
    this.sharePercentageTarget.value = newValue
    this.sharePercentageTarget.focus()
    this.sharePercentageTarget.blur()
    this.sharePercentageTarget.classList.add("d-none")
    this.portfolioPercentageTarget.innerText = `${quotePart.toFixed(2)}%`
    this.actionTarget.innerText = `Conservé : ${newValue.toFixed(2)}%`
  }
  complete(event) {
    event.preventDefault()
    let total = this.totalCategory()
    let currentValue = parseFloat(this.sharePercentageTarget.value)
    let newValue = currentValue + (100 - total)
    this.sharePercentageTarget.value = parseFloat(newValue)
    this.completeInputTarget.value = 1
    this.element.closest('[data-controller="composition-category"]')
                .querySelectorAll('[data-target="composition-share.complete"]')
                .forEach((element) => {
                  element.classList.add('d-none')
                })
    this.sharePercentageTarget.focus()
    this.sharePercentageTarget.blur()
    this.sharePercentageTarget.classList.add("d-none")
    this.keepTarget.remove()
    let quotePart = (this.portfolioPercentage() * newValue)
    this.portfolioPercentageTarget.innerText = `${quotePart.toFixed(2)}%`
    this.actionTarget.innerText = `Complété : ${parseFloat(newValue).toFixed(2)}%`
  }

  portfolioPercentage() {
    return parseFloat(this.element
                  .closest('[data-controller="composition-category"]')
                  .dataset.compositionCategoryPercentage) / 100
  }

  totalCategory() {
    return parseFloat(this.element
                  .closest('[data-controller="composition-category"]')
                  .dataset.total)
  }

}
