import { Controller } from "stimulus"
import Rails from 'rails-ujs'

export default class extends Controller {

  static targets = [
    'portfolio',
    'submit',
    'feedbackCompleted'
  ]

  connect() {
    this.submitTarget.classList.add('d-none')

    const completedObserver = new MutationObserver(function(mutationsList, observer) {
      var completedByPortfolio = this.portfolioTargets.map((portfolio) => portfolio.dataset.categoryCompleted)
      if (completedByPortfolio.some((completed) => completed != "1")) {
        this.submitTarget.classList.add('d-none')
        this.feedbackCompletedTarget.classList.remove('d-none')
      } else {
        this.submitTarget.classList.remove('d-none')
        this.feedbackCompletedTarget.classList.add('d-none')
      }
    }.bind(this))

    this.portfolioTargets.forEach((portfolio) => {
      completedObserver.observe(portfolio, { attributes: true })
    })
  }


}
