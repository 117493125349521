import { Controller } from "stimulus"
import Inputmask from "inputmask"
const moment = require('moment')

export default class extends Controller {
  static targets = [
    'input'
  ]

  connect() {
    console.log('dateMask')
    var im = new Inputmask("99/99/9999", {
      placeholder: "JJ/MM/AAAA",
      alias: "datetime",
      inputFormat: "dd/mm/yyyy",
      inputMode: "numeric"
    })
    this.inputTargets.forEach((inputTarget) => {
      im.mask(inputTarget)
    })
  }
}