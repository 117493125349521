import { Controller } from 'stimulus'
import Papa from 'papaparse'

export default class extends Controller {
  static targets = [
    "submit",
    "input",
    "template",
    "links",
    "closeModal"
  ]

  loadCSV() {
    let _this = this
    Papa.parse(this.inputTarget.files[0], {
      header: true,
      complete: (results) => {
        this.element.querySelector("[data-new-record]").remove()
        results.data.filter(row => row.eos_category_id).forEach((row) => {
          let target = _this.resolveData(row)
          _this.appendTarget(target)
        })
      }
    })
  }

  resolveData(row) {
    return {
      category_percentage: parseFloat(row.category_percentage.replace(',', '.')) * 100, // "0,4"
      eos_category_id: row.eos_category_id, // "19"
      shares_percentage: parseFloat(row.shares_percentage.replace(',', '.')) * 100, // "0,2"
      hedge: row.hedge, // "FALSE",
      hedge_currency: row.hedge_currency // "USD"
    }
  }

  appendTarget(target) {
    const date = new Date().getTime()
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, date)
    const temp = document.createElement('div')
    temp.innerHTML = content
    const htmlObject = temp.firstElementChild
    htmlObject.id = "target-" + date
    this.linksTarget.insertAdjacentHTML('beforebegin', htmlObject.outerHTML)
    this.distributeFields(htmlObject.id, target)
    this.closeModalTarget.click()
  }

  distributeFields(id, target) {
    let el = document.getElementById(id)
    el.querySelector('.category_percentage').value = target.category_percentage
    el.querySelector('.eos_category_id').value = target.eos_category_id
    el.querySelector('.shares_percentage').value = target.shares_percentage
    if (target.hedge == "TRUE") {
      el.querySelector('.hedge').checked = true
      el.querySelector('.hedge_currency').classList.remove("opacity-0")
      el.querySelector('.hedge_currency').disabled = false
    }
    el.querySelector('.hedge_currency').value = target.hedge_currency
  }
}