import ApplicationController from './application_controller'
/* This is the custom StimulusReflex controller for the EosCategory Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [
    "inputCategoryId",
    "inputPosition",
    "positionLabel",
    "groupPosition",
    "submitButton",
    "targetEosCategory",
    "funds"
  ]
 
  connect() {

  }

  changeTargetCategory(event) {
    event.preventDefault()
    this.setTargetEosCategory(event)
    this.toggleForm()
  }
  
  setTargetEosCategory(event) {
    if (!this.inputCategoryIdTarget.value) { 
      this.targetEosCategoryTarget.innerHTML = ''
    } else {
      let _this = this
      let eosCategoryId = event.target.value
      
      let eos_category_url = `/admin/eos_categories/${eosCategoryId}/target`
      Rails.ajax({
        type: "GET",
        url: eos_category_url,
        success: (data) => {
          _this.targetEosCategoryTarget.innerHTML = data.html
          this.setPositionInput()
        }
      })
    }
  }
  
  setPositionInput() {
      let funds = this.fundsTarget
      let fundsCount = parseInt(funds.dataset.fundsCount)
      let lastPosition = fundsCount + 1
      this.inputPositionTarget.value = lastPosition
  }

  toggleForm() {
    if (this.inputCategoryIdTarget.value) { this.showfields() }
    else { this.hideFields() }
  }

  showfields() {
    this.positionLabelTarget.classList.remove('d-none')
    this.groupPositionTarget.classList.remove('d-none')
    this.submitButtonTarget.classList.remove('d-none')
  }

  hideFields() {
    this.positionLabelTarget.classList.add('d-none')
    this.groupPositionTarget.classList.add('d-none')
    this.submitButtonTarget.classList.add('d-none')
  }

}
