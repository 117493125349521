import { Controller } from "stimulus"
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    "spInventory",
    "contractField"
  ]

  connect() {

  }

  displayInventory() {
    this.setSpInventory()
  }

  setSpInventory() {
    let _this = this
    let portfolioId = this.spInventoryTarget.dataset.portfolioId
    let contractId = this.contractFieldTarget.value
    let inventory_url = `/portfolios/${portfolioId}/inventory_sp?contract_id=${contractId}`
    this.showLoading(this.spInventoryTarget)

    Rails.ajax({
      type: "GET",
      url: inventory_url,
      success: (data) => {
        _this.spInventoryTarget.innerHTML = data.html
        _this.hideLoading(_this.spInventoryTarget)
      }
    })
  }

  showLoading(el) {
    el.insertAdjacentHTML('beforeend', this.loadingElement())
  }

  hideLoading(el) {
    if (el.querySelector('.loading')) {
      el.querySelector('.loading').remove()
    }
  }

  loadingElement() {
    return `
      <div class="loading bg-white d-flex justify-content-center align-items-center">
        <div class="spinner-grow text-secondary" role="status" style="width: 6rem; height: 6rem;">
          <span class="sr-only">Chargement...</span>
        </div>
      </div>
    `
  }

}
