import '../../stylesheets/application.scss';
import Rails from 'rails-ujs';
import "summernote";
import "summernote/dist/summernote-bs4.css";
import 'bootstrap';
import Turbolinks from 'turbolinks';
import "extensions/turbolinks"
import flatpickr from 'flatpickr';
import { French } from "flatpickr/dist/l10n/fr.js"

require("flatpickr/dist/flatpickr.css")

import Sortable from 'sortablejs';
require("channels")

Rails.start();
window.Rails = Rails
Turbolinks.start()

import "../components/import_notification"
import "../components/import_breakdowns"

document.addEventListener('turbolinks:load', () => {
  flatpickr("[data-behavior='flatpickr-range']", {
    mode: "range",
    altInput: true,
    allowInput: true,
    altFormat: 'd/m/Y',
    dateFormat: "Y-m-d",
    locale: French
  })
  flatpickr("[data-behavior='flatpickr-simple']", {
    altInput: true,
    allowInput: true,
    altFormat: 'd/m/Y',
    dateFormat: "Y-m-d",
    locale: French,
    onReady: function(dateObj, dateStr, instance) {
      var $cal = $(instance.calendarContainer);
      if ($cal.find('.flatpickr-clear').length < 1) {
          $cal.append('<div class="flatpickr-clear">Clear</div>');
          $cal.find('.flatpickr-clear').on('click', function() {
              instance.clear();
              instance.close();
          });
      }
    }
  })

  var date = new Date();

  flatpickr("[data-behavior='flatpickr-simple-min-tomorrow']", {
    altInput: true,
    allowInput: true,
    altFormat: 'd/m/Y',
    dateFormat: "Y-m-d",
    minDate: date.setDate(date.getDate() + 1),
    locale: French,
    onReady: function(dateObj, dateStr, instance) {
      var $cal = $(instance.calendarContainer);
      if ($cal.find('.flatpickr-clear').length < 1) {
          $cal.append('<div class="flatpickr-clear">Clear</div>');
          $cal.find('.flatpickr-clear').on('click', function() {
              instance.clear();
              instance.close();
          });
      }
    }
  })
})

document.addEventListener('turbolinks:load', () => {
  if(document.getElementById('eos-category-list') !== null) {
    var el = document.getElementById('eos-category-list');
    var sortable = Sortable.create(el, { animation: 150 });
  }
})

$('.summernote').summernote({
  height: 300,
  tabsize: 2,
  toolbar: [
    ['style', ['bold', 'italic', 'underline']],
    ['para', ['ul']]
  ],
  fontsize: '14',
  fontSizes: ['14'],
  popover: {
    link: [
      ['link', ['linkDialogShow', 'unlink']]
    ]
  }
})

$('.note-editable').css('font-size','14px');
$('.note-editable span').css('font-size','14px');

$("#share-search").submit(function() {
  $('#shares').html('<div class="text-center my-5"><div class="spinner-border text-primary" style="width: 12rem; height: 12rem;" role="status"><span class="sr-only">Loading...</span></div></div>');
});

$("#isin-canary").submit(function(e) {
  $('#isin-form .modal-body .form-group').hide()
  $('#isin-form .modal-body').append('<div class="text-center"><div class="spinner-border text-primary" style="width: 5rem; height: 5rem;" role="status"><span class="sr-only">Loading...</span></div></div>');
});

$("body").on('submit', "#import-navs-form", function() {
  $('#import-navs-form').hide()
  $('#import-navs-form').closest(".modal-content").append('<div class="text-center my-5"><h4 class="mb-5">Import des NAV en cours...</h4><div class="spinner-border text-primary" style="width: 12rem; height: 12rem;" role="status"><span class="sr-only">Loading...</span></div></div>');
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
});

$(function(){
  $('.modal-autoload').modal('show');
});

window.$ = $;
window.jQuery = jQuery;

$('.modal').on('shown.bs.modal', function() {
  $(this).find('[autofocus]').focus();
});

import "controllers"
