import { Controller } from "stimulus"
import Rails from "rails-ujs"
import { useLoading } from '../mixins/useLoading.js'

export default class extends Controller {
  connect() {
    useLoading(this)
    console.log("url loader")
  }

  url(portfolio_id, startAt, endAt) {
    let url = new URL(window.location.protocol + "//" + window.location.host + this.element.dataset.url.replace(':portfolio_id', portfolio_id))
    let params = {
      start_at: startAt,
      end_at: endAt
    }
    Object.entries(params).forEach((param) => {
      url.searchParams.append(param[0], param[1])
    })
    return url
  }

  display(e) {
    this.showLoading()
    Rails.ajax({
      type: "GET",
      url: this.url(e.detail.portfolio_id, e.detail.startAt, e.detail.endAt),
      success: (data) => {
        this.element.innerHTML = data.html
        this.hideLoading()
      }
    })
  }
}
