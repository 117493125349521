document.addEventListener("turbolinks:click", (event) => {
  const anchorElement = event.target


  event.preventDefault()

  Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(
    event.data.url,
    Turbolinks.uuid()
  )
})