import { Controller } from "stimulus"
import { useDispatch } from 'stimulus-use'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    "transactionsType",
    "transactionAmount",
    "targetQuotePart",
    "targetAmount",
    "transactionAmountInput",
    "currentAmount",
    "targetAmount",
    "newAmount",
    "transactionPercent",
    "transactionPercentageInput",
    "currentQuotePart"
  ]

  connect() {
    useDispatch(this)
  }

  displayTransactionColumns(event) {
    event.preventDefault()
    let transactionsType = this.transactionsTypeTarget
    
    if (event.detail) {
      if (transactionsType.dataset.draftDone == 'true' || ((transactionsType.dataset.shareId != event.detail.shareId)) ) { return }
    }

    let transactionsTypeValue = transactionsType.value
    transactionsType.dataset.draftDone = true

    var categoryId = transactionsType.dataset.categoryId
    var shareId = transactionsType.dataset.shareId
    var destroy = false

    if (transactionsTypeValue == '1') { this.setBuyElements() }
    else if (transactionsTypeValue == '2') { this.setSellElements() }
    else if (transactionsTypeValue == '3') { this.setTotalSellElements() }
    else if (transactionsTypeValue == '4') { 
      // deleted share datas
      var destroy = true 
      var shareId = event.target.dataset.shareId
      var categoryId = event.target.dataset.categoryId
    }

    else { this.setDisabledElements()}

    let kind = this.transactionPercentageInputTarget.dataset.kind

    this.showElement(this.transactionPercentTarget)

    this.dispatch('updateShareFlows', { 
      kind: kind,
      destroy: destroy,
      shareId: shareId,
      categoryId: categoryId
    })
  }

  transactionColumnsElements() {
    return [
      this.transactionsTypeTarget,
      this.transactionAmountInputTarget,
      this.targetAmountTarget,
      this.targetQuotePartTarget,
      this.transactionPercentageInputTarget
    ]
  }

  setBuyElements() {
    let transactionColumnsElements = this.transactionColumnsElements()
    transactionColumnsElements.forEach(element => this.setBuyElementStyle(element))
    let cAmount = this.currentAmountTarget.value
    this.transactionAmountInputTarget.value = 0.0
    this.transactionAmountInputTarget.readOnly = false
    this.transactionAmountInputTarget.max = ""
    
    this.transactionPercentageInputTarget.value = 0.0
    this.transactionPercentageInputTarget.readOnly = false
    this.transactionPercentageInputTarget.max = ""

    this.targetAmountTarget.innerHTML = `${this.numberWithSpacesHtml(Number(cAmount).toFixed(2).toString().split(".").join(","))}&nbsp;€`
    this.targetQuotePartTarget.innerHTML = "-"

    this.newAmountTarget.value = Number(this.currentAmountTarget.value)

    this.targetQuotePartTarget.dataset.targetQuotePart = this.currentQuotePartTarget.dataset.value
  }

  setSellElements() {
    let transactionColumnsElements = this.transactionColumnsElements()
    transactionColumnsElements.forEach(element => this.setSellElementStyle(element))
    let cAmount = this.currentAmountTarget.value
    let cQuotePart = this.currentQuotePartTarget.dataset.quotePart

    this.transactionAmountInputTarget.value = 0.0
    this.transactionAmountInputTarget.readOnly = false
    this.transactionAmountInputTarget.max = Number(cAmount).toFixed(2)
    
    this.transactionPercentageInputTarget.value = 0.0
    this.transactionPercentageInputTarget.readOnly = false
    this.transactionPercentageInputTarget.max = Number(cQuotePart).toFixed(2)

    this.targetAmountTarget.innerHTML = `${this.numberWithSpacesHtml(Number(cAmount).toFixed(2).toString().split(".").join(","))}&nbsp;€`
    this.targetQuotePartTarget.innerHTML = "-"

    this.newAmountTarget.value = Number(this.currentAmountTarget.value)

    this.targetQuotePartTarget.dataset.targetQuotePart = this.currentQuotePartTarget.dataset.value
  }

  setTotalSellElements() {
    let transactionColumnsElements = this.transactionColumnsElements()
    transactionColumnsElements.forEach(element => this.setTotalSellElementStyle(element))
    let cAmount = this.currentAmountTarget.value
    let cQuotePart = this.currentQuotePartTarget.dataset.quotePart

    this.transactionAmountInputTarget.value = Number(cAmount).toFixed(2)
    this.transactionAmountInputTarget.readOnly = true
    
    this.transactionPercentageInputTarget.value = Number(cQuotePart).toFixed(2)
    this.transactionPercentageInputTarget.readOnly = true

    this.targetAmountTarget.innerHTML = "-"
    this.targetQuotePartTarget.innerHTML = "-"

    this.newAmountTarget.value = 0.0
    this.targetQuotePartTarget.dataset.targetQuotePart = 0.0
  }

  setDisabledElements() {
    let transactionColumnsElements = this.transactionColumnsElements()
    transactionColumnsElements.forEach(element => this.setDisabledElementStyle(element))

    this.transactionAmountInputTarget.value = 0.0
    this.transactionAmountInputTarget.readOnly = false
    this.transactionAmountInputTarget.max = Number(this.currentAmountTarget.value)
    
    this.transactionPercentageInputTarget.value = 0.0
    this.transactionPercentageInputTarget.readOnly = false
    this.transactionPercentageInputTarget.max = Number(this.currentQuotePartTarget.dataset.quotePart).toFixed(2)

    this.targetAmountTarget.innerHTML = "-"
    this.targetQuotePartTarget.innerHTML = this.percentageElement(Number(this.currentQuotePartTarget.dataset.quotePart))

    this.newAmountTarget.value = Number(this.currentAmountTarget.value)

    this.targetQuotePartTarget.dataset.targetQuotePart = this.currentQuotePartTarget.dataset.quotePart
  }

  setBuyElementStyle(element) {
    this.showElement(element)
    element.classList.remove('border-warning', 'text-warning', 'border-red', 'text-red')
    element.classList.add('border-success', 'text-success')
  }

  setSellElementStyle(element) {
    this.showElement(element)
    element.classList.remove('border-success', 'text-success', 'border-red', 'text-red')
    element.classList.add('border-warning', 'text-warning')
  }

  setTotalSellElementStyle(element) {
    this.showElement(element)
    element.classList.remove('border-success', 'text-success', 'border-warning', 'text-warning')
    element.classList.add('border-red', 'text-red')
  }

  setDisabledElementStyle(element) {
    element.classList.remove('border-success', 'text-success', 'border-warning', 'text-warning', 'border-red', 'text-red')
    if (element != this.transactionsTypeTarget && element != this.targetQuotePartTarget) {
      this.hideElement(element)
    }
  }

  updateShareFlows(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    var value = event.target.valueAsNumber
    var kind = event.target.dataset.kind

    if (value == 0.0) { return false }
    let amount
    let newQuotePart
    let currentPortfolioValue = this.element.dataset.portfolioValue
    let transactionsType = this.transactionsTypeTarget.value
    let cAmount = Number(this.currentAmountTarget.value)

    if (kind == "amount") { // Si en montants
      amount = value
      newQuotePart = amount / currentPortfolioValue
    } else if (kind == "percentage") { // Si en pourcentage
      amount = (value / 100) * currentPortfolioValue
    }

    if (isNaN(amount)) { amount = 0.0 }
    if (isNaN(newQuotePart)) { newQuotePart = 0.0 }

    if (transactionsType == '1') {
      var newAmount = cAmount + amount
      if (kind == "amount") { this.transactionPercentageInputTarget.value = newQuotePart * 100 }
      if (kind == "percentage") { this.transactionAmountInputTarget.value = Number(amount) }
    } else if (transactionsType == '2') {
      amount = this.checkMax(amount)
      newQuotePart = amount / currentPortfolioValue
      if (kind == "amount") { this.transactionPercentageInputTarget.value = newQuotePart * 100 }
      if (kind == "percentage") { this.transactionAmountInputTarget.value = Number(amount) }
      var newAmount = cAmount - amount
    } else if (transactionsType == '3') {
      var newAmount = 0.0
      this.targetQuotePartTarget.value = 0.0
      this.targetQuotePartTarget.dataset.quotePart = 0.0
      this.targetAmountTarget.value = 0.0
      this.newAmountTarget.value = 0.0
    }

    this.targetAmountTarget.innerHTML = `${this.numberWithSpacesHtml(newAmount.toFixed(2).toString().split(".").join(","))}&nbsp;€`

    this.newAmountTarget.value = Number(newAmount)
    // update de la quote part
    this.dispatch('updateShareFlows', { kind: kind, value: value})
  }

  checkMax(transactionAmount) {
    let value
    let cAmount = Number(this.currentAmountTarget.value)

    if (transactionAmount > cAmount) {
      value = cAmount.toFixed(2)
    } else {
      value = transactionAmount.toFixed(2)
    }
    return value
  }

  hideElement(el) {
    el.classList.add('d-none')
  }

  showElement(el) {
    el.classList.remove('d-none')
  }

  numberWithSpacesHtml(x) {
    var parts = x.toString().split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;")
    return parts.join(".")
  }

  numberWithEuroSpacesHtml(x) {
    `${this.numberWithSpacesHtml(Number(cAmount).toFixed(2).toString().split(".").join(","))}&nbsp;€`
  }

  // Plus utilisé 
  processShareFlows() {
    let transactionsType = this.transactionsTypeTarget.value
    // Si en montants 
    let cAmount = Number(this.currentAmountTarget.value)
    let amount = Number(this.transactionAmountInputTarget.value)

    if (transactionsType == '1') {
      var newAmount = cAmount + amount
    } else if (transactionsType == '2') {
      amount = this.checkMax(amount)
      var newAmount = cAmount - amount
    } else if (transactionsType == '3') {
      var newAmount = cAmount - amount
    }
    this.targetAmountTarget.innerHTML = `${this.numberWithSpacesHtml(newAmount.toFixed(2))}&nbsp;€`
    // Si en parts
  }

  percentageElement(value) {
    if (value != 0.0) {
      return `${value.toFixed(2).toString().split(".").join(",")}&nbsp;%`
    } else {
      return `-`
    }
  }

}
