import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "generate",
    "generating",
    "download",
    "percentage",
    "progress",
    "missingShares",
    "selected",
    "checkbox",
    "emptySelection",
    "remember",
    "rememberWrapper",
    "action",
  ]

  connect() {
    let selection = JSON.parse(this.context.element.dataset.selection)
    if (selection) {
      Object.keys(selection).forEach((portfolio_id) => {
        let portfolio = document.getElementById(`portfolio-${portfolio_id}`)
        if (!portfolio) return
        portfolio.checked = true
        let contracts_ids = selection[portfolio_id]
        if (Array.isArray(contracts_ids)) {
          portfolio.indeterminate = contracts_ids.length < this.contractsFor(portfolio).length
          contracts_ids.forEach((contract_id) => {
            let contract = document.getElementById(`contract-${portfolio_id}-${contract_id}`)
            if (!contract) return
            contract.checked = true
          })
        }
      })
    } else {
      this.checkAll()
      this.rememberTarget.checked = true
    }
  }

  checkAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = true
      checkbox.indeterminate = false
    })
    this.rememberTarget.checked = false
  }

  uncheckAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
      checkbox.indeterminate = false
    })
    this.rememberTarget.checked = false
  }

  portfolioFor(contract) {
    return contract.closest('.portfolio-check-wrapper').querySelector('.portfolio-check')
  }
  contractsFor(portfolio, pseudoclass = "") {
    return portfolio.closest('.portfolio-check-wrapper').querySelectorAll(`.contract-check${pseudoclass}`)
  }

  check(e) {
    this.rememberTarget.checked = false
    let element = e.currentTarget
    let type = element.dataset.type
    if (type == "portfolio") {
      this.contractsFor(element).forEach((contract) => {
        contract.checked = element.checked
      })
    } else if (type == "contract") {
      let portfolio = this.portfolioFor(element)
      let contractCount = this.contractsFor(portfolio).length
      let contractCheckedCount = this.contractsFor(portfolio, ":checked").length
      portfolio.checked = contractCheckedCount > 0
      portfolio.indeterminate = contractCheckedCount > 0 && contractCheckedCount < contractCount
    }
  }

  generate(e) {
    e.preventDefault()
    let form = new FormData(document.forms['selectedPortfolios'])
    let json = JSON.stringify(this.objectFromFormData(form))
    console.log(this.objectFromFormData(form))
    if (json == "{}") {
      this.emptySelectionTarget.classList.remove('d-none')
      return
    }

    this.checkboxTargets.forEach((checkbox) => checkbox.disabled = true)
    this.actionTargets.forEach((action) => action.classList.add('d-none'))
    this.rememberWrapperTarget.classList.add('d-none')
    this.emptySelectionTarget.classList.add('d-none')

    var formData = new FormData()
    formData.append('payload', json)
    formData.append('remember', this.rememberTarget.checked)

    Rails.ajax({
      type: "POST",
      data: formData,
      dataType: "json",
      url: this.data.get('generate'),
      success: (data) => {
        this.generating(data.jid)
      }
    })
  }

  generating(jid) {
    let url = new URL(this.data.get('generate'))
    url.searchParams.set('jid', jid)
    this.generateTarget.classList.add('d-none')
    this.generatingTarget.classList.remove('d-none')
    this.ping(url)
  }

  ping(url) {
    let that = this
    Rails.ajax({
      type: "POST",
      url: url.href,
      success: (data) => {
        if (data.status == "complete") {
          let missing_shares = JSON.parse(data.missing_shares)
          if (missing_shares.length > 0) {
            this.missingSharesTarget.innerHTML = data.missing_shares_html
          }
          this.percentageTarget.innerText = data.pct_complete
          this.progressTarget.style.width = data.pct_complete + "%"
          let that = this
          setTimeout(function () {
            that.generatingTarget.classList.add('d-none')
            that.downloadTarget.classList.remove('d-none')
          }, 500)
        } else {
          this.percentageTarget.innerText = data.pct_complete || 0
          this.progressTarget.style.width = data.pct_complete + "%"
          setTimeout(function() {
            that.ping(url)
          }, 1000)
        }
      }
    })
  }

  objectFromFormData(formData) {
    var values = {};
    for (var pair of formData.entries()) {
      var key = pair[0];
      var value = pair[1];
      if (values[key]) {
        if (!(values[key] instanceof Array)) {
          values[key] = new Array(values[key]);
        }
        values[key].push(value);
      } else {
        values[key] = value;
      }
    }
    return values;
  }
}
