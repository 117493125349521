import ApplicationController from './application_controller'
/* This is the custom StimulusReflex controller for the EosCategory Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [
    'formTemplate',
    'funds',
    'fundsList',
    'searchForm',
    'searchFormWrapper',
    'searchField',
    'client',
    'main',
    'noFunds'
  ]

  static values = { 
    id: Number
  }

  // events
  // connect() {
  //   // this.resetFirstAndLastClasses()
  //   // this.focusSearchField()
  //   // this.resetSearchForm()
  //   // this.disableClientIfMain()
  // }
  
  sort(event) {
    let element = this.fundsListTarget
    let fund_elements = document.getElementsByClassName('eos-category-item')
    let funds = Array.from(fund_elements).map((fund, index) => {
      return { 
        id: fund.dataset.eosCategoryFundId,
        position: (index + 1),
        fund_id: fund.dataset.eosCategoryFund
      }
    })
    element.dataset.funds = JSON.stringify(funds)
    this.stimulate('EosCategoryReflex#sort', element, { serializeForm: true })
  }
  
  add(event) {
    let fundId = event.target.dataset.fundId
    let eosCategoryId = this.idValue
    
    if (this.fundInList(fundId)) {
      return false
    }
    
    this.stimulate('EosCategoryReflex#add', fundId, eosCategoryId)
  }
  
  remove(event) {
    event.preventDefault()
    let eosCategoryId = this.idValue
    let fundId = event.target.dataset.fundId

    this.stimulate('EosCategoryReflex#remove', fundId, eosCategoryId)
  }

  updateEosCategory(event) {
    event.preventDefault()
    let eosCategoryId = event.target.value

    this.stimulate('EosCategoryReflex#remove', fundId, eosCategoryId)
  }

  nextPosition() {
    return this.fundsTarget.querySelectorAll('[data-fund]').length
  }

  fundInList(fundId) {
    return this.fundsIds().includes(parseInt(fundId))
  }

  fundsIds() {
    return Array.prototype.map.call(
      this.fundsTarget.querySelectorAll('[data-fund]'),
      function(item) {
        return parseInt(item.dataset.fund)
      }
    )
  }

  moveHigher(fund) {
    var actualPosition = parseInt(fund.querySelector('.fund-position').value)
    fund.querySelector('[data-position]').innerHTML = actualPosition - 1
    fund.querySelector('.fund-position').value = actualPosition - 1
  }

  hideNoFund() {
    this.noFundsTarget.classList.add('d-none')
  }

  getNextSibling(elem, selector) {
  	var sibling = elem.nextElementSibling
  	if (!selector) return sibling
  	while (sibling) {
  		if (sibling.matches(selector)) return sibling
  		sibling = sibling.nextElementSibling
  	}
  }
}
