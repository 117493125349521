import { Controller } from "stimulus"
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [ "contractList" ]

  connect() {
    this.contractListTargets.forEach(function(contractList){
      var insurerId = contractList.dataset.insurer
      var selectedContractId = contractList.dataset.selected
      if (insurerId == undefined) { return false }
      Rails.ajax({
        type: "GET",
        url: "/admin/insurers/" + insurerId + "/contracts",
        success: (data) => {
          contractList.disabled = false
          var child = contractList.lastElementChild
          while (child) {
            contractList.removeChild(child)
            child = contractList.lastElementChild
          }

          data.forEach(function(contract){
            var option = document.createElement('option')
            option.appendChild( document.createTextNode(contract.name) )
            option.value = contract.id
            contractList.appendChild(option)
          })
          contractList.querySelector('option[value="' + selectedContractId + '"]').selected = "selected"
        }
      }, this)
    })
  }

  set_contract_list(event) {
    var insurerId = event.target.value
    Rails.ajax({
      type: "GET",
      url: "/admin/insurers/" + insurerId + "/contracts",
      success: (data) => {
        var contractList = this.contractListTarget
        contractList.disabled = false
        var child = contractList.lastElementChild
        while (child) {
          contractList.removeChild(child)
          child = contractList.lastElementChild
        }

        data.forEach(function(contract){
          var option = document.createElement('option')
          option.appendChild( document.createTextNode(contract.name) )
          option.value = contract.id
          contractList.appendChild(option)
        })
      }
    })
  }


}
