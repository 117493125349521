import { Controller } from "stimulus"
import Rails from "rails-ujs"
import Highcharts from "highcharts"
const moment = require('moment')

export default class extends Controller {
  static targets = [
    "graph",
    "end",
    "legend"
  ]

  connect() {
    let _this = this

    let isNavs = _this.data.get("is-navs")
    let end = _this.data.get("end")

    if (isNavs == 'false') {
      _this.hideLoading()
      _this.showNavsError()
      return false
    }

    this.displayGraph([])
    var observer = new IntersectionObserver(function(entries) {
      if (_this.loaded) { return false }

    	if (entries[0].isIntersecting === true) {
        _this.showLoading()

        let portfolioId = _this.data.get("portfolio-id")
        
        Rails.ajax({
          type: "GET",
          url: "/portfolios/" + portfolioId + `/asset_breakdown.json?end=${end}`,
          success: (data) => {
            _this.displayGraph(data)
            _this.loaded = true
          }
        })
      }
    }, { threshold: [0] })

    observer.observe(this.graphTarget)
  }

  displayGraph(data) {
    data = data.filter((asset) => asset.y > 0.0)
    let options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        spacingBottom: 10,
        spacingLeft: 10,
        spacingRight: 10,
        spacingTop: 10,
        margin: 0,
        height: 300,
        width: 300
      },
      title: {
        text: ''
      },
      loading: {
        labelStyle: {
          color: 'white'
        },
        style: {
          backgroundColor: 'gray'
        }
      },
      lang: {
        loading: "Chargement..."
      },
      tooltip: {
        outside: true,
        backgroundColor: '#FFFFFF',
        shadow: {
          width: 10,
          opacity: 0.02
        },
        style: {
          fontFamily: 'work sans',
        },
        borderWidth: 0,
        useHTML: true,
        formatter: function () {
          return `
            <div>
              <div class="d-flex align-items-center text-muted">
                <div class="mr-1" style="width: 10px; height: 10px; background: ${this.point.color}"></div>
                ${this.point.name}
              </div>
              <div class="font-lg line-height-sm font-weight-medium">${this.point.percentage.toFixed(2)}%</div>
            </div>
          `
        },
      },
      legend: { enabled: false },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: true,
          innerSize: '50%',
          states: {
            hover: {
              halo: {
                size: 0
              }
            }
          },
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [{
        colorByPoint: true,
        data: data
      }]
    }
    if (this.data.get("simple")) {
      options['chart']['height'] = 166
      options['chart']['width'] = 166
    }
    this.chart = Highcharts.chart(this.graphTarget, options)
    this.chart.reflow()

    this.showLegend(data)
    this.hideLoading()
  }

  legendElement(item, sumItems) {
    return `
      <li style="min-width:300px" class="pie-legend ${item.ellipsis ? "my-0 line-height-sm" : "" } d-flex align-items-center ${this.data.get("simple") ? "font-sm" : ""}">
        <div class="mr-2 legend-block flex-shrink-0" style="background-color:${item.color}"></div>
        <div class="text-muted flex-grow-1">
          ${item.name}
        </div>
        <div class="flex-shrink-0 text-primary font-weight-medium text-left" style="flex: 0 0 70px">
          ${item.y == null ? "..." : `${(item.y).toFixed(1)}%`}
        </div>
      </li>
    `
  }

  legendsElement(data) {
    let sumItems = this.sumLegend(data)
    return data.reduce((str, item) => {
      return str + this.legendElement(item, sumItems)
    }, "")
  }

  sumLegend(data) {
    return data.reduce((sum, item) => {
        return sum + item.y
    }, 0)
  }

  showLegend(data) {
    data = data.sort((a, b) => b.y - a.y)

    let dataCount = data.length
    if (this.data.get("simple")) {
      data = data.slice(0, 5)
    }
    this.legendTarget.innerHTML = `
      <ul class="d-flex flex-column align-items-end p-0 mt-2 mb-0">
        ${this.legendsElement(data)}
      </ul>
    `
    if (dataCount > data.length) {
      this.legendTarget.querySelector("ul").insertAdjacentHTML('beforeend', `
        ${this.legendElement({name: "...", y: null, color: "white", ellipsis: true})}
      `)
    }
  }

  updateGraph(data) {
    data = data.filter((asset) => asset.y > 0.0)
    this.chart.series[0].setData(data)
    this.chart.redraw()
    this.showLegend(data)
    this.hideLoading()
  }

  loadingElement() {
    return `
      <div class="loading bg-white d-flex justify-content-center align-items-center">
        <div class="spinner-grow text-secondary" role="status" style="width: 6rem; height: 6rem;">
          <span class="sr-only">Chargement...</span>
        </div>
      </div>
    `
  }
  showLoading() {
    this.context.element.insertAdjacentHTML('beforeend', this.loadingElement())
  }
  hideLoading() {
    if (this.context.element.querySelector('.loading')) {
      this.context.element.querySelector('.loading').remove()
    }
  }

  showNavsError() {
    this.context.element.insertAdjacentHTML('beforeend', this.NavsErrorElement())
  }
  NavsErrorElement() {
    return `
      <div class="error-nav bg-white d-flex justify-content-center align-items-center">
        <div class="text-center" role="status" style="width: 30rem; height: 6rem; padding-top:30px;">
        <span class="badge badge-light-grey">
          calcul en cours
        </span>
        </div>
      </div>
    `
  }
}
