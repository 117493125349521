import { Controller } from "stimulus"
import Inputmask from "inputmask"
const moment = require('moment')

export default class extends Controller {
  static targets = [
    'start',
    'end'
  ]

  connect() {
    this.startTarget.value = moment(this.data.get("endAt")).startOf('year').format('DD/MM/YYYY')
    this.endTarget.value = moment(this.data.get("endAt")).format('DD/MM/YYYY')
    var im = new Inputmask("99/99/9999", {
      placeholder: "JJ/MM/AAAA"
    })
    im.mask(this.startTarget)
    im.mask(this.endTarget)
  }

  changePeriod(event) {
    let selectedPeriodElement = event.currentTarget.options[event.currentTarget.selectedIndex]
    this.data.set("startAt", selectedPeriodElement.dataset.startAt)
    this.data.set("endAt", selectedPeriodElement.dataset.endAt)
    this.connect()
  }

  changeDates(event) {
    let start = moment(this.startTarget.value, 'DD/MM/YYYY')
    let end = moment(this.endTarget.value, 'DD/MM/YYYY')
    let min = moment(this.data.get('min'))
    let max = moment(this.data.get('max'))
    if (start < min) {
      alert(`La date de début ne peut être inférieure au ${min.format('DD/MM/YYYY')} (date de création du profil)`)
      this.startTarget.value = min.format('DD/MM/YYYY')
    }
    if (start > max) {
      alert(`La date de début ne peut être supérieure au ${max.format('DD/MM/YYYY')}`)
      this.startTarget.value = max.format('DD/MM/YYYY')
    }
    if (start > end) {
      alert(`La date de début ne peut être supérieure à la date de fin`)
      this.resetDates() 
      this.startTarget.setSelectionRange(0, this.startTarget.value.length)
      return false
    }
    if (end > max) {
      alert(`La date de fin ne peut être supérieure au ${max.format('DD/MM/YYYY')}`)
      this.endTarget.value = max.format('DD/MM/YYYY')
    }
  }
}
