import { Controller } from "stimulus"
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    'sharePercentage',
    'totalPercentage',
    'share',
    'percentage',
    'keep',
    'keepInput',
    'complete',
    'completeInput',
    'displayPercentage'
  ]

  connect() {
    this.processTotalCategory()
    this.processKeep()
  }

  processKeep() {
    if (this.totalCategoryToKeep() > 0) {
      this.keepTarget.classList.remove("d-none")
      this.keepTarget.innerText = `Conserver (${this.totalCategoryToKeep().toFixed(2)}%)`
    }
  }

  totalCategoryToKeep() {
    return this.shareTargets.reduce((sum, share) => {
      return sum + (parseFloat(share.dataset.compositionShareQuotePart) || 0)
    }, 0.0)
  }

  sharesHavingQuotePart() {
    return this.shareTargets.filter((share) => {
      return parseFloat(share.dataset.compositionShareQuotePart) > 0
    })
  }

  sharesNotHavingQuotePart() {
    return this.shareTargets.filter((share) => {
        return !share.dataset.compositionShareQuotePart
    })
  }

  keep(event) {
    event.preventDefault()
    this.percentageTarget.value = this.totalCategoryToKeep()
    this.keepTarget.remove()
    this.completeTarget.remove()
    this.percentageTarget.classList.add('d-none')
    this.displayPercentageTarget.innerHTML = ` - Conservé (${this.totalCategoryToKeep().toFixed(2)}%)`
    this.sharesNotHavingQuotePart().forEach((share) => share.remove())
    this.data.set("percentage", this.totalCategoryToKeep())
    this.keepInputTarget.value = 1
    var event = new CustomEvent("keepCategoryPercentage", { detail: { percentage: this.totalCategoryToKeep() } })
    this.sharesHavingQuotePart().forEach((share) => share.dispatchEvent(event))
    this.processTotalCategory()
  }

  complete(event) {
    event.preventDefault()
    let completePercentage = 100 - this.totalCategoriesPercentage()
    let newValue = parseFloat(this.percentageTarget.value) + completePercentage
    this.percentageTarget.value = newValue
    this.keepTarget.remove()
    this.completeTarget.remove()
    this.percentageTarget.classList.add('d-none')
    this.displayPercentageTarget.innerHTML = ` - Complété (${newValue.toFixed(2)}%)`
    this.data.set("percentage", newValue)
    this.completeInputTarget.value = 1

    var event = new CustomEvent("completeCategoryPercentage", { detail: { percentage: newValue } })
    this.sharesHavingQuotePart().forEach((share) => share.dispatchEvent(event))
    this.processTotalCategory()
  }

  totalCategoriesPercentage() {
    return Array.from(
            this.element.closest('[data-controller="composition-portfolio"]')
            .querySelectorAll('[data-controller="composition-category"]')
          )
          .reduce((sum, element) => {
            return sum + parseFloat(element.dataset.compositionCategoryPercentage)
          }, 0.0)
    }

  totalCategory() {
    return this.sharePercentageTargets.reduce(function(sum, input) {
      return sum + parseFloat(input.value)
    }, 0.0)
  }

  processTotalCategory() {
    this.totalPercentageTarget.innerHTML = this.totalHtml(this.totalCategory().toFixed(2))
    this.element.dataset.total = this.totalCategory()
  }

  totalHtml(total) {
    return `
      <strong class="${total == 100 ? 'text-success' : 'text-danger'}">
        ${total != 100 ? '❌' : '✅'}
        TOTAL: ${isNaN(total) ? "ERREUR" : total + "%"}
      </strong>
    `
  }

  deleteShare(event) {
    event.preventDefault()
    let shareToRemove = event.currentTarget.closest('[data-controller="composition-share"]')

    let remainingShares = this.shareTargets.filter(function(share, index){
        return ((share != shareToRemove) && (share.dataset.compositionShareSharesCount))
    })
    let categoryNav = remainingShares.reduce((sum, share) => {
        return sum + parseFloat(share.dataset.compositionShareSharesCount) * parseFloat(share.dataset.compositionShareNav)
    }, 0.0)

    remainingShares.forEach((share) => {
      let newValue = ((parseFloat(share.dataset.compositionShareSharesCount) * parseFloat(share.dataset.compositionShareNav)) / categoryNav) * 100
      share.dataset.percentageKeepCategory = newValue
      // share.querySelector('[data-target="composition-share.keepPercentage"]').innerText = `Conserver (${newValue.toFixed(2)}%)`
    })
    event.currentTarget.closest(".category-share").remove()
    this.processTotalCategory()
  }

  showMore(event) {
    event.preventDefault()
    this.load(parseInt(this.data.get("maxSharePercentage")) / 2)
  }

  reload(event) {
    event.preventDefault()
    this.load()
  }

  load(maxSharePercentageInput = null) {
    if (this.completeInputTarget.value == "1") {
      var compositonPortfolioEl = this.element.closest('[data-controller="composition-portfolio"]')
      compositonPortfolioEl.dataset.categoryCompleted = parseInt(compositonPortfolioEl.dataset.categoryCompleted) - 1
    }
    let buttonsToShow = this.element.closest('[data-controller="composition-portfolio"]').querySelectorAll('[data-target*="composition-category.complete"]')

    buttonsToShow.forEach(function (button) {
      button.classList.remove('d-none')
    })
    let portfolioId = this.data.get("portfolio")
    let categoryId = this.data.get("id")
    let percentage = this.data.get("percentage-base")
    let maxSharePercentage = maxSharePercentageInput || this.data.get("maxSharePercentage")
    let hedged = this.data.get("hedged")
    let hedgedCurrency = this.data.get("hedgedCurrency")
    let date = this.data.get("date")

    let data = {
      eos_category: categoryId,
      percentage: percentage,
      max_share_percentage: maxSharePercentage,
      hedged: hedged,
      hedged_currency: hedgedCurrency,
      date: date
    }

    Rails.ajax({
      type: "POST",
      url: "/admin/portfolios/" + portfolioId + "/composition_category",
      dataType: "script",
      data: this.toQueryString(data)
    })
  }

  toQueryString(data) {
    return Object.keys(data).map(key => key + '=' + data[key]).join('&')
  }
}
