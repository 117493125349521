import ApplicationController from './application_controller'
import Rails from 'rails-ujs'

export default class extends ApplicationController {
  static targets = [
    "endDate",
    "portfolioId",
    "eosCategoryId",
    "shares"
  ]

  static values = { 
    categoryId: Number
  }

  connect() {

  }

  reload(event) {
    event.preventDefault()
    this.load()
  }
  
  load() {
    let portfolioId = this.portfolioIdTarget.value
    let eosCategoryId = this.eosCategoryIdTarget.value
    let date = this.getDateFromPicker()
    let inventoryDate = this.dateStringFormat(date)

    let data = {
      eos_category: eosCategoryId,
      date: inventoryDate
    }

    Rails.ajax({
      type: "POST",
      url: "/admin/portfolios/" + portfolioId + "/composition_eos_category",
      dataType: "script",
      data: this.toQueryString(data)
    })
  }

  add(event) {
    event.preventDefault()

    let shareId = event.target.dataset.shareId

    if (this.shareInList(shareId)) {
      this.alertSharePresent(shareName, shareIsin, event.target)
      return
    }

    let shareName = event.target.dataset.shareName
    let shareIsin = event.target.dataset.shareIsin
    let eosCategoryId = event.target.dataset.eosCategoryId
    let date = this.getDateFromPicker()
    let inventoryDate = this.dateStringFormat(date)    
    let categoryPresence = this.categoryInList(eosCategoryId)

    if (categoryPresence) {
      // console.log("la catégorie est présente dans l'inventaire")
      this.setShareLine(eosCategoryId, shareId, inventoryDate)
      this.alertShareAdded(shareName, shareIsin, event.target)
    }
    else {
      // console.log("la catégorie n'est pas présente dans l'inventaire")
      // Insérer la catégory et la share à la fin de 'shares'
      this.setcategoryLine(eosCategoryId, shareId, inventoryDate)
      this.alertShareAdded(shareName, shareIsin, event.target)
    }
  }

  setcategoryLine(eosCategoryId, shareId, inventoryDate) {
    let portfolioId = this.portfolioIdTarget.value
    let lastInventoryLine = document.querySelector('#inventory')
    let url = `/admin/compositions/${portfolioId}/category_line?share_id=${shareId}&category_id=${eosCategoryId}&date=${inventoryDate}`

    Rails.ajax({
      type: "GET",
      url: url,
      success: (data) => {
        lastInventoryLine.insertAdjacentHTML('beforeend', data.html)
      }
    })
  }

  setShareLine(eosCategoryId, shareId, inventoryDate) {
    let portfolioId = this.portfolioIdTarget.value
    let ShareLines = this.categoryShareLines(eosCategoryId)
    let lastShareLine = ShareLines.pop()
    let url = `/admin/compositions/${portfolioId}/share_line?share_id=${shareId}&category_id=${eosCategoryId}&date=${inventoryDate}`
    // url test : '/admin/compositions/255/share_line?share_id=8296&category_id=19&date=2022-08-22'

    Rails.ajax({
      type: "GET",
      url: url,
      success: (data) => {
        lastShareLine.insertAdjacentHTML('afterend', data.html)
      }
    })
  }

  categoryShareLines(eosCategoryId) {
    let x = Array.prototype.map.call(
      this.sharesTarget.querySelectorAll('[data-category]'),
      function(item) {
        if (item.dataset.category == eosCategoryId) {
          return item
        }
      }
    ).filter(element => {
      return element !== undefined
    })
    return x
  }

  shareInList(shareId) {
    return this.sharesIds().includes(parseInt(shareId))
  }

  sharesIds() {
    return Array.prototype.map.call(
      this.sharesTarget.querySelectorAll('[data-share]'),
      function(item) {
        return parseInt(item.dataset.share)
      }
    )
  }

  categoryInList(categoryId) {
    return this.categorysIds().includes(parseInt(categoryId))
  }

  categorysIds() {
    return Array.prototype.map.call(
      this.sharesTarget.querySelectorAll('[data-category]'),
      function(item) {
        return parseInt(item.dataset.category)
      }
    )
  }

  getDateFromPicker(){
    return new Date(this.endDateTarget.value)
  }

  toQueryString(data) {
    return Object.keys(data).map(key => key + '=' + data[key]).join('&')
  }

  dateStringFormat(date) {
    return date.toISOString().split('T')[0]
  }

  alertSharePresent(name, isin, eventTarget) {
    eventTarget.parentElement.parentElement.insertAdjacentHTML('beforebegin', 
    `
      <div class=" alert alert-danger text-center">
        Cette part est déjà présente dans l'inventaire
      </div>
    `
    )
  }

  alertShareAdded(name, isin, eventTarget) {
    eventTarget.parentElement.parentElement.insertAdjacentHTML('beforebegin', 
    `
      <div class=" alert alert-success text-center">
        Cette part a bien été ajoutée dans l'inventaire : ${name} - ${isin}
      </div>
    `
    )
  }
}
