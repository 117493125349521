import { createConsumer } from "@rails/actioncable"

const App = {};
App.cable = createConsumer();

window.contractImportSubscription = function(contractId) {
  App.cable.subscriptions.create({
    channel: "ImportNotificationChannel",
    contract_id: contractId
  },
  {
    received: function(data) {
      if (data.status == "error") {
        document.getElementById('reportErrorWrapper').innerHTML = data.error_html
      } else {
        if (data.count) {
          var progress = document.querySelector('#import-progress .progress-bar')
          if (data.count > data.total) { 
            var value = 100
          } else {
            var value = parseInt((data.count / data.total) * 100) + "%"
          }
          progress.style.width = value
          progress.innerHTML = value
        } else {
          document.getElementById('reportWrapper').innerHTML = data.report_html;
          document.getElementById('contractShares').innerHTML = data.contract_shares_html;
          document.getElementById('reportSuccess').classList.remove('d-none');
        }
      }
    }
  })
}
