export const useLoading = controller => {
  Object.assign(controller, {
    loadingElement() {
      return `
        <div class="loading bg-white d-flex justify-content-center align-items-center">
          <div class="spinner-grow text-secondary" role="status" style="width: 6rem; height: 6rem;">
            <span class="sr-only">Chargement...</span>
          </div>
        </div>
      `
    },
    showLoading() {
      this.context.element.insertAdjacentHTML('beforeend', this.loadingElement())
    },
    hideLoading() {
      if (this.context.element.querySelector('.loading')) {
        this.context.element.querySelector('.loading').remove()
      }
    }
  });
};