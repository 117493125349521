import { Controller } from "stimulus"
import Highcharts from "highcharts"
import Rails from "rails-ujs"
import { useLoading } from '../mixins/useLoading.js'

export default class extends Controller {
  connect() {
    useLoading(this)
    console.log("bar chart")
  }

  url(portfolio_id, startAt, endAt) {
    let url = new URL(window.location.protocol + "//" + window.location.host + this.element.dataset.url.replace(':portfolio_id', portfolio_id))
    let params = {
      start_at: startAt,
      end_at: endAt
    }
    Object.entries(params).forEach((param) => {
      url.searchParams.append(param[0], param[1])
    })
    return url
  }

  display(e) {
    this.showLoading()
    Rails.ajax({
      type: "GET",
      url: this.url(e.detail.portfolio_id, e.detail.startAt, e.detail.endAt),
      success: (data) => {
        if (this.data.get('colors') == "false") {
          this.stripColor(data)
        }
        this.chart = Highcharts.chart(this.element, this.barChartOptions(data))
        this.hideLoading()
      }
    })
  }

  stripColor(data) {
    data.series.forEach((serie) => {
      serie.data.forEach((data) => {
        data.color = null
      })
    })
  }

  barChartOptions(data) {
    return {
      chart: {
        type: 'bar',
        height: 300,
        clip: false,
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      xAxis: {
        type: "linear",
        categories: data.categories,
        labels: {
          style: {
            color: '#8694A2'
          }
        },
        lineWidth: 1,
        lineColor: "#D6E1EB",
        tickInterval: 1,
        tickWidth: 1,
        tickmarkPlacement: "on"
      },
      yAxis: {
        allowDecimals: true,
        title: {
          text: null
        },
        labels: {
          format: '{value}%',
          style: {
            color: '#8694A2'
          }
        },
        lineWidth: 1,
        lineColor: "#A1AEBB",
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        bar: {
          grouping: false,
          shadow: false,
          borderWidth: 0
        }
      },
      series: data.series.map((serie) => {
        return Object.assign({
          animation: false,
          pointWidth: 20
        }, serie)
      }),
      tooltip: {
        backgroundColor: '#FFFFFF',
        shadow: {
          width: 10,
          opacity: 0.02
        },
        style: {
          fontFamily: 'work sans',
        },
        borderWidth: 0,
        outside: true,
        useHTML: true,
        formatter: function () {
          return this.points.reduce(function (s, point, currentIndex) {
            return `
              ${s}
              <div class="mt-2 ${currentIndex == 0 ? "text-primary" : "text-muted"}">
                <div class="d-flex align-items-center text-muted">
                  <div class="mr-1" style="width: 10px; height: 10px; background: ${point.series.color}"></div>
                  <div class="${currentIndex == 0 ? "text-primary" : "text-muted"}">
                    ${point.series.name}
                  </div>
                </div>
                <div class="font-lg line-height-sm font-weight-medium">${point.y.toFixed(2)}%</div>
              </div>
            `
          }, `<div class="text-center">${this.x}</div>`);
        },
        shared: true
      }
    }
  }
}
