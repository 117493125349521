import { Controller } from "stimulus"
import Rails from 'rails-ujs'

export default class extends Controller {

  static targets = [
    'categories',
    'share',
    'description',
    'structure',
    'composition',
    'category',
    'total',
    'completeButton'
  ]

  connect() {
    $('.summernote').summernote({
      height: 300,
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['para', ['ul', 'ol']]
      ],
      popover: {
        link: [
          ['link', ['linkDialogShow', 'unlink']]
        ]
      }
    })
    this.setDescription()

    const compositionObserver = new MutationObserver(function(mutationsList, observer) {
      this.setDescription()
    }.bind(this))
    compositionObserver.observe(this.compositionTarget, { childList: true, subtree: true })

    const totalObserver = new MutationObserver(function(mutationsList, observer) {
      this.processTotal()
    }.bind(this))
    this.categoryTargets.forEach((category) => {
      totalObserver.observe(category, { childList: true, subtree: true })
    })

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  }

  completeCategory(event) {
    let buttonsToHide = this.completeButtonTargets.filter(function(button) {
      return button != event.currentTarget
    }).forEach(function(button) {
      button.classList.add('d-none')
    })
    const categoryCompeleted = this.element.dataset.categoryCompleted
    this.element.dataset.categoryCompleted = parseInt(categoryCompeleted) + 1
  }

  processTotal() {
    let total = this.categoryTargets.reduce((sum, category) => {
      return sum + parseFloat(category.dataset.compositionCategoryPercentage)
    }, 0.0)
    total = parseFloat(total.toPrecision(9))

    this.totalTarget.innerHTML = `
      <strong class="${total == 100 ? 'text-success' : 'text-danger'}">
        ${total != 100 ? '❌' : '✅'}
        TOTAL: ${isNaN(total) ? "ERREUR" : total.toFixed(2) + "%"}
      </strong>
    `
  }

  setDescription(){
    let description = ""
    this.shareTargets.reverse().forEach(function(shareTarget) {
      description += `<b>${shareTarget.dataset.shareName}</b><br>`
      if (shareTarget.dataset.fundDescription) {
        description += `${shareTarget.dataset.fundDescription}<br><br>`
      }
    })
    $(this.descriptionTarget).summernote("code", description)

    let structure = ""
    let rootEl = this.scope.element
    structure += `<p><b>${rootEl.dataset.compositionPortfolioName}</b></p>`
    rootEl.querySelectorAll('[data-controller="composition-category"]').forEach(function(categoryEl){
      structure += `
        <b>${categoryEl.dataset.compositionCategoryName}</b>
        ${categoryEl.dataset.compositionCategoryPercentage}% <br>
        <ul>
      `
      categoryEl.querySelectorAll('[data-controller="composition-share"]').forEach(function(shareEl){
        structure += `
          <li>
            <b>${shareEl.dataset.shareName}</b>
            ${(shareEl.querySelector('.share-input').value * categoryEl.dataset.compositionCategoryPercentage) / 100}%
            ${shareEl.dataset.fundDescription}
          </li>
        `
      })
      structure += `</ul>`
    })

    $(this.structureTarget).summernote("code", structure)
  }
}
