import { Controller } from "stimulus"
import Rails from 'rails-ujs'
import "summernote"
import 'summernote/dist/summernote-bs4.css';

export default class extends Controller {
  static targets = [
    "subProfileList",
    "portfolioList",
    "totalPercentage",
    "compositionPercentage",
    "portfolioCheckbox",
    "compositionCategories"
  ]

  connect() {
    $('.summernote').summernote({
      height: 300,
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['para', ['ul', 'ol']]
      ],
      popover: {
        link: [
          ['link', ['linkDialogShow', 'unlink']]
        ]
      }
    })
  }

  displayComposition() {
    if (Array.prototype.slice.call(this.portfolioCheckboxTargets).some(x => x.checked)) {
      this.compositionCategoriesTarget.classList.remove('d-none')
    } else {
      this.compositionCategoriesTarget.classList.add('d-none')
    }
  }

  processTotalPortfolio() {
    let total = this.compositionPercentageTargets.reduce(function(sum, input) {
      return sum + parseFloat(input.value)
    }, 0.0)

    total = parseFloat(total.toPrecision(9))
    this.totalPercentageTarget.innerHTML = this.totalHtml(total)
  }

  totalHtml(total) {
    return `
      <strong class="${total == 100 ? 'text-success' : 'text-danger'}">
        ${total != 100 ? '❌' : '✅'}
        TOTAL: ${isNaN(total) ? "ERREUR" : total.toFixed(2) + "%"}
      </strong>
    `
  }

  setSubProfilesList(event) {
    var profileId = event.target.value
    Rails.ajax({
      type: "GET",
      url: "/admin/profiles/" + profileId + "/sub_profiles",
      success: (data) => {
        var subProfileList = this.subProfileListTarget
        subProfileList.disabled = false
        var child = subProfileList.lastElementChild
        while (child) {
          subProfileList.removeChild(child)
          child = subProfileList.lastElementChild
        }

        var option = document.createElement('option')
        option.appendChild( document.createTextNode("-- Choisir un sous profil --") )
        subProfileList.appendChild(option)

        data.forEach(function(subProfile){
          var option = document.createElement('option')
          option.appendChild( document.createTextNode(subProfile.name) )
          option.value = subProfile.id
          subProfileList.appendChild(option)
        })
      }
    })
  }

  setPortfoliosList(event) {
    var subProfileId = event.target.value
    var context = this
    Rails.ajax({
      type: "GET",
      url: "/admin/sub_profiles/" + subProfileId + "/portfolios",
      success: (data) => {
        var portfolioList = this.portfolioListTarget
        portfolioList.innerHTML = ""
        portfolioList.innerHTML = this.portfolioHeader(data)
        data.forEach(function(portfolio){
          var portfolioCheckbox = context.portfolioTemplate(portfolio)
          portfolioList.insertAdjacentHTML('beforeend', portfolioCheckbox)
        })
      }
    })
  }

  portfolioHeader() {
    return `
      <h3 class="mt-4">3) Sélection des portefeuilles du sous-profil</h3>
    `
  }

  portfolioTemplate(portfolio) {
    return `
      <div class="form-check">
        <input
          class="form-check-input check_boxes optional"
          type="checkbox"
          value="${portfolio.id}"
          name="composition[portfolio][]"
          id="composition_portfolio_${portfolio.id}"
          data-action="composition#displayComposition"
          data-target="composition.portfolioCheckbox">
        <label class="collection_check_boxes" for="composition_portfolio_${portfolio.id}">
          ${portfolio.name}
        </label>
      </div>
    `
  }
}
