import { Controller } from "stimulus"
import Rails from "rails-ujs"
const moment = require('moment')

export default class extends Controller {
  static targets = [
    "inventory",
    "transactions",
    "portfolioContractId",
    "btnExcel",
    "btnO2S",
    "btnPDF"
  ]

  connect() {
  }

  tablesAccordingToContract() {
    this.changeContractInventory()
    this.changeContractTransactions()

    let regex = /contract_id=(\d+)/gi

    this.btnPDFTarget.href = this.btnPDFTarget.href.replace(regex, "contract_id=" + this.portfolioContractIdTarget.value)

    if (this.hasBtnExcelTarget && this.hasBtnO2STarget) {
      this.btnExcelTarget.href = this.btnExcelTarget.href.replace(regex, "contract_id=" + this.portfolioContractIdTarget.value)
      this.btnO2STarget.href = this.btnO2STarget.href.replace(regex, "contract_id=" + this.portfolioContractIdTarget.value)
    }
  }

  changeContractInventory() {
    var inventory_url
    let _this = this
    let portfolioId = this.data.get("portfolio-id")
    let statusDone = this.data.get("status-done")
    let effectiveDate = moment(this.data.get("effective-date")).format('DD/MM/YYYY')

    this.inventoryTarget.innerHTML = this.loadingElementSm()

    if (statusDone == 'false') {
      inventory_url = `/portfolios/${portfolioId}/inventory?simple=true&pending=true&contract_id=${this.portfolioContractIdTarget.value}`
    } else {
      inventory_url = `/portfolios/${portfolioId}/inventory?simple=true&end=${effectiveDate}&contract_id=${this.portfolioContractIdTarget.value}`
    }

    Rails.ajax({
      type: "GET",
      url: inventory_url,
      success: (data) => {
        _this.inventoryTarget.innerHTML = data.html
        _this.hideLoading(_this.inventoryTarget)
      }
    })
  }

  changeContractTransactions() {
    let _this = this
    let arbitrageId = this.data.get("arbitrage-id")
    let effectiveDate = moment(this.data.get("effective-date")).format('DD/MM/YYYY')

    this.transactionsTarget.innerHTML = this.loadingElementSm()

    let transactions_url = `/arbitrages/${arbitrageId}/transactions?date=${effectiveDate}&contract_id=${this.portfolioContractIdTarget.value}`

    Rails.ajax({
      type: "GET",
      url: transactions_url,
      success: (data) => {
        _this.transactionsTarget.innerHTML = data.html
        _this.hideLoading(_this.transactionsTarget)
      }
    })
  }

  loadingElementSm() {
    return `
        <div class="bg-white py-5 d-flex justify-content-center align-items-center">
        <div class="spinner-grow text-secondary" role="status" style="width: 6rem; height: 6rem;">
          <span class="sr-only">Chargement...</span>
        </div>
      </div>
    `
  }

  loadingElement() {
    return `
      <div class="loading bg-white d-flex justify-content-center align-items-center">
        <div class="spinner-grow text-secondary" role="status" style="width: 6rem; height: 6rem;">
          <span class="sr-only">Chargement...</span>
        </div>
      </div>
    `
  }
  showLoading(el) {
    el.insertAdjacentHTML('beforeend', this.loadingElement())
  }
  hideLoading(el) {
    if (el.querySelector('.loading')) {
      el.querySelector('.loading').remove()
    }
  }
}
