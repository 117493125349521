import { Controller } from "stimulus"
import Rails from "rails-ujs"
import Highcharts from "highcharts"

export default class extends Controller {

  connect() {
    this.graphId = this.context.element.id
    let shareId = this.data.get("share-id")

    Rails.ajax({
      type: "GET",
      url: "/shares/" + shareId + "/performance.json",
      success: (data) => {
        this.displayGraph(data)
      }
    }, this)
  }

  displayGraph(data) {
    let options = {
      chart: {
        type: 'line'
      },
      title: {
        text: null
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: [
       	{
          title: {
            text: null,
          }
        },
        {
          title: {
            text: 'Valeur',
          }
       	}
      ],
      series: [
        {
          type: 'line',
          name: 'Fonds',
          data: data.share,
        }
      ]
    }
    var chart = Highcharts.chart(this.context.element, options)
  }
}
